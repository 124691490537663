import React, { Component, useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';
import DefaultImage from '../../assets/img/default-image.jpg';

import { GET_ADMIN_BY_ID, GET_INSTANSI } from 'utils';
import axios from 'axios';
import { EDIT_ADMIN_BY_ID, GET_TOKEN } from 'utils';
import jwt_decode from 'jwt-decode';

export default function EditAdminUser() {
  const { id } = useParams();
  const history = useHistory();

  const [gambar, setGambar] = useState('');

  const [nama, setNama] = useState('');
  const [username, setUsername] = useState('');
  const [instansi, setInstansi] = useState('');
  const [status, setStatus] = useState('');
  const [akses, setAkses] = useState('');
  const [password, setPassword] = useState('');
  const [passwordBaru, setPasswordBaru] = useState('');
  const [gantiPassword, setGantiPassword] = useState('');
  const [checked, setChecked] = useState('');

  const [urlFile1, setUrlFile1] = useState(DefaultImage);
  const [gantiGambar, setGantiGambar] = useState('tidak');
  const [idAdmin, setIdAdmin] = useState('');

  const [saveLoading, setSaveLoading] = useState(false);

  // auth

  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    getDetailAdmin();
  }, []);

  const getDetailAdmin = async () => {
    const user = JSON.parse(window.localStorage.getItem('user'));

    setUrlFile1(user.avatar);
    setNama(user.nama);
    setUsername(user.namaPengguna);
    setInstansi(user.unitKerja);
    setStatus(user.status);
    setAkses(user.akses);
    setPassword(user.password);
  };

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setGambar(e.target.files[0]);
    setGantiGambar('ya');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setSaveLoading(true);

    // if (nama && instansi && username && password) {
    //   //action
    //   // this.props.dispatch(updateJersey(this.state));

    //   const formData = new FormData();
    //   formData.append('photo', gambar);
    //   formData.append('nama', nama);
    //   formData.append('instansi', instansi);
    //   formData.append('status', status);
    //   formData.append('akses', checked.toString());
    //   formData.append('gantiGambar', gantiGambar);
    //   formData.append('username', username);
    //   formData.append('idAdmin', idAdmin);

    //   const response = await axios.patch(
    //     EDIT_ADMIN_BY_ID + `${idAdmin}`,
    //     formData,
    //     {
    //       credentials: 'include',
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //         Authorization: `Bearer ${accessToken}`,
    //       },
    //     }
    //   );

    //   if (response.data.success === 1) {
    //     setSaveLoading(false);
    //     swal('Success', 'Data admin berhasil diedit', 'success');
    //     history.push('/admin/dashboard');
    //   }
    // } else {
    //   setSaveLoading(false);
    //   swal('Failed', 'Maaf semua form wajib diisi', 'error');
    // }
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/Dashboard' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>{nama}</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <img
                          src={urlFile1}
                          width='300'
                          alt='Foto Berita (satu)'
                        />
                        <FormGroup>
                          <label>Foto Admin</label>
                          <Input
                            type='file'
                            name='image1'
                            accept={'image/*'}
                            onChange={(event) => onChange1(event, 'imageToDB1')}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Nama</label>
                      <Input
                        type='text'
                        value={nama}
                        name='nama'
                        onChange={(event) => setNama(event.target.value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <label>username</label>
                      <Input
                        type='text'
                        name='liga'
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                      />
                    </FormGroup>

                    {/* <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Password</label>
                          <Input
                            type='text'
                            value={password}
                            name='harga'
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <label>Password Baru</label>
                          <Input
                            type='text'
                            value={passwordBaru}
                            name='harga'
                            onChange={(event) =>
                              setPasswordBaru(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}

                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <label>Instansi</label>
                          <Input type='text' value={instansi} name='berat' />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Status Admin</label>
                          <Input type='text' value={status} name='berat' />
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* <Row>
                      <Col>
                        <FormGroup>
                          <label>Hak akses kepada layanan</label>
                          <FormGroup>
                            <Input type='text' value={akses} name='harga' />
                          </FormGroup>
                        </FormGroup>
                      </Col>
                    </Row> */}

                    {/* <Row>
                      <Col md={6}></Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Ready</label>
                          <Input type='select' name='ready' value=''>
                            <option value={true}>Ada</option>
                            <option value={false}>Kosong</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row> */}
                  </Col>
                </Row>

                {/* <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row> */}
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
