/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  Dashboard,
  ListDaftarHadir,
  ListDaftarHadirShift,
  EditDaftarHadir,
  TambahDaftarHadir,
  LaporBulan,
  RekapOrang,
  CatatDH,
  LaporHari,
  EditAdminUser,
  RegisterASN,
  LaporanKinerjaShift,
  DetailShift,
} from "./views";

import img1 from "./assets/img/attendance.png";
import img2 from "./assets/img/calendar.png";
import img3 from "./assets/img/calendar2.png";
import img4 from "./assets/img/health-check.png";
import img5 from "./assets/img/report.png";
import user from "./assets/img/user.png";

import kinerja from "./assets/img/kinerja.png";
import allakun from "./assets/img/all-akun.png";
import admin from "./assets/img/admin.png";
import akunliist from "./assets/img/akun-list.png";
import pd from "./assets/img/organisasi.png";

import dhpd from "./assets/img/immigration.png";

import LaporMinggu from "views/DaftarHadir/LaporMinggu";
import DetailMinggu from "views/DaftarHadir/DetailMinggu";
import ListAkun from "views/DaftarHadir/ListAkun";
import EditASNUser from "views/DaftarHadir/EditASNUser";
import AllASN from "views/DaftarHadir/AllASN";
import LaporHariPD from "views/DaftarHadir/LaporHariPD";
import LaporRangePD from "views/DaftarHadir/LaporRangePD";
import KinerjaPD from "views/DaftarHadir/KinerjaPD";
import LaporHarianPD from "views/DaftarHadir/LaporHarianPD";
import TPPAsn from "views/DaftarHadir/TPPAsn";
import DetailTPP from "views/DaftarHadir/DetailTPP";
import UsersSmartDH from "views/DaftarHadir/UsersSmartDH";
import Libur from "./views/DaftarHadir/Libur";

var routes = [
  {
    path: "/daftarhadir",
    name: "Daftar Hadir",
    icon: img5,
    component: ListDaftarHadir,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "admin,user,iphone",
  },
  {
    path: "/shift",
    name: "shift",
    icon: img4,
    component: ListDaftarHadirShift,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "admin,shift",
  },

  {
    path: "/daftarhadir/edit/:id",
    name: "Edit Daftar Hadir",
    component: EditDaftarHadir,
    layout: "/admin",
    sidebar: false,
    menu: "Daftar Hadir",
    akses: "admin",
  },
  {
    path: "/daftarhadir/tambah",
    name: "Tambah Daftar Hadir",
    component: TambahDaftarHadir,
    layout: "/admin",
    sidebar: false,
    menu: "Daftar Hadir",
    akses: "admin",
  },

  {
    path: "/laporkinerja",
    name: "Laporan Kinerja",
    icon: img4,
    component: LaporMinggu,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "admin,user,iphone",
  },

  {
    path: "/detailkinerja/:id",
    name: "Detail Kinerja",
    icon: img4,
    component: DetailMinggu,
    layout: "/admin",
    sidebar: false,
    menu: "Daftar Hadir",
    akses: "admin,user,iphone",
  },
  {
    path: "/detailshift/:id",
    name: "Detail Shift",
    icon: img4,
    component: DetailShift,
    layout: "/admin",
    sidebar: false,
    menu: "Daftar Hadir",
    akses: "admin,shift,super admin",
  },
  {
    path: "/kinerjadetailshift/:id",
    name: "Detail Kinerja Shift",
    icon: img4,
    component: DetailMinggu,
    layout: "/admin",
    sidebar: false,
    menu: "Daftar Hadir",
    akses: "admin,super admin",
  },
  {
    path: "/detailtpp/:id",
    name: "Detail TPP",
    icon: img4,
    component: DetailTPP,
    layout: "/admin",
    sidebar: false,
    menu: "Daftar Hadir",
    akses: "admin,user,iphone",
  },
  {
    path: "/rekaporang/:id",
    name: "Rekapan perOrang",
    icon: img3,
    component: RekapOrang,
    layout: "/admin",
    sidebar: false,
    menu: "Daftar Hadir",
    akses: "admin,user,iphone",
  },
  {
    path: "/catatdh",
    name: "Catat Daftar Hadir",
    icon: img1,
    component: CatatDH,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "iphone",
  },
  {
    path: "/laporhari",
    name: "Laporan Harian",
    icon: img2,
    component: LaporHari,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "admin,user,iphone",
  },
  {
    path: "/listakun",
    name: "Daftar Akun",
    icon: akunliist,
    component: ListAkun,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "admin,user,iphone",
  },
  {
    path: "/listakun/edit/:id",
    name: "Edit Akun",
    icon: user,
    component: EditASNUser,
    layout: "/admin",
    sidebar: false,
    menu: "Daftar Hadir",
    akses: "admin,super admin",
  },

  {
    path: "/perangkatdaerah",
    name: "Laporan Harian",
    icon: dhpd,
    component: LaporHariPD,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "super admin",
  },
  {
    path: "/perangkatdaerah/detail/:id",
    name: "Perangkat Daerah Detail",
    icon: user,
    component: LaporHarianPD,
    layout: "/admin",
    sidebar: false,
    menu: "Daftar Hadir",
    akses: "super admin",
  },
  {
    path: "/laporanPD",
    name: "Laporan Perangkat Daerah",
    icon: pd,
    component: LaporRangePD,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "super admin",
  },
  {
    path: "/lapkinerjashift",
    name: "Laporan Kinerja Shift",
    icon: img4,
    component: LaporanKinerjaShift,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "admin,shift,super admin",
  },
  {
    path: "/registerasn",
    name: "Registrasi ASN",
    icon: pd,
    component: RegisterASN,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "super admin",
  },
  {
    path: "/libur",
    name: "Libur",
    icon: pd,
    component: Libur,
    layout: "/admin",
    sidebar: true,
    menu: "Libur",
    akses: "super admin",
  },

  {
    path: "/kinerjaPD/:id",
    name: "Kinerja Perangkat Daerah",
    icon: kinerja,
    component: KinerjaPD,
    layout: "/admin",
    sidebar: false,
    menu: "Daftar Hadir",
    akses: "super admin",
  },
  {
    path: "/tpp/asn",
    name: "Laporan TPP ASN",
    icon: kinerja,
    component: TPPAsn,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "user,admin,iphone",
  },
  {
    path: "/all",
    name: "Semua User ASN",
    icon: allakun,
    component: AllASN,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "super admin",
  },
  {
    path: "/listadmin",
    name: "Smart Admin",
    icon: admin,
    component: UsersSmartDH,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "super admin",
  },
  {
    path: "/edituser",
    name: "Smart Akun",
    icon: user,
    component: EditAdminUser,
    layout: "/admin",
    sidebar: true,
    menu: "Daftar Hadir",
    akses: "admin,user,iphone,super admin",
  },
];
export default routes;
