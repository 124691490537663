import { hitungSelisihWaktu } from "utils/function";
import FIREBASE from "../config/FIREBASE";
import { dispatchError, dispatchLoading, dispatchSuccess } from "../utils";
import {
  API_DAFTARHADIR,
  API_TIMEOUT,
  API_DAFTARHADIR_CEK,
  WAKTU_KERJA5,
} from "../utils/constant";

import axios from "axios";
export const CATAT_DH_MASUK = "CATAT_DH_MASUK";
export const CATAT_DH_PULANG = "CATAT_DH_PULANG";
export const GET_LIST_DH = "GET_LIST_DH";
export const GET_LIST_DH_BULANAN = "GET_LIST_DH_BULANAN";
export const DELETE_DH = "DELETE_DH";
export const CLEAR_DH = "CLEAR_DH";
export const CLEAR_CEK_CATATAN = "CLEAR_CEK_CATATAN";
export const CEK_CATATAN_DH = "CEK_CATATAN_DH";
export const CLEAR_CATAT_PULANG = "CLEAR_CATAT_PULANG";

export const clearDH = () => {
  return (dispatch) => {
    dispatchSuccess(dispatch, CLEAR_DH, "");
  };
};

export const clearCekCatatan = () => {
  return (dispatch) => {
    dispatchSuccess(dispatch, CLEAR_CEK_CATATAN, "");
  };
};

export const catatMasuk = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, CATAT_DH_MASUK);

    axios({
      method: "post",
      url: API_DAFTARHADIR,
      timeout: API_TIMEOUT,
      data: {
        tahun: data.tahun,
        bulan: data.bulan,
        tanggal: data.tanggal,
        nama: data.nama,
        opd: data.opd,
        waktuMasuk: data.waktuMasuk,
        waktuPulang: data.waktuPulang,
        status: data.status,
        koordinatMasuk: data.koordinatMasuk,
        koordinatPulang: data.koordinatPulang,
        workingHours: data.workingHours,
        token: data.token,
        idUser: data.idUser,
      },
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          alert("error");
          dispatchError(dispatch, CATAT_DH_MASUK, "error");
        } else {
          //BERHASIL
          var dataKirim = {
            id: response.data.data.insertId,
            status: data.status,
          };
          dispatchSuccess(dispatch, CATAT_DH_MASUK, response ? dataKirim : []);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
        dispatchError(dispatch, CATAT_DH_MASUK, "error");

        //alert(error);
      });
  };
};

export const cekCatatanDH = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, CEK_CATATAN_DH);

    axios({
      method: "post",
      url: API_DAFTARHADIR_CEK,
      timeout: API_TIMEOUT,
      data: {
        tanggal: data.tanggal,
        nama: data.nama,
      },
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, CEK_CATATAN_DH, "error");
        } else {
          //BERHASIL
          const dataku = response ? response.data.data[0] : [];
          dispatchSuccess(dispatch, CEK_CATATAN_DH, dataku);
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, CEK_CATATAN_DH, "error");
        // alert(error);
      });
  };
};

export const cekCatatanDH2 = (data) => {
  axios({
    method: "post",
    url: API_DAFTARHADIR_CEK,
    timeout: API_TIMEOUT,
    data: {
      tanggal: data.tanggal,
      nama: data.nama,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        // ERROR
        return "error";
      } else {
        //BERHASIL
        const dataku = response ? response : [];

        return dataku;
      }
    })
    .catch((error) => {
      // ERROR
      return error;
      //alert(error);
    });
};
export const catatPulang = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, CATAT_DH_PULANG);

    axios({
      method: "post",
      url: API_DAFTARHADIR + "/" + "pulang",
      timeout: API_TIMEOUT,
      data: {
        waktuPulang: data.waktuPulang,
        koordinatPulang: data.koordinatPulang,
        workingHours: data.workingHours,
        token: data.token,
        id: data.id,
      },
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, CATAT_DH_PULANG, "error");
        } else {
          //BERHASIL
          const dataku = response ? response.data : [];
          dispatchSuccess(dispatch, CATAT_DH_PULANG, dataku);
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, CATAT_DH_PULANG, "error");
        alert(error);
      });
  };
};

export const clearCatatPulang = () => {
  return (dispatch) => {
    dispatchSuccess(dispatch, CLEAR_CATAT_PULANG, "");
  };
};

export const getListDaftarHadir = (data) => {
  const unitKerja = data.unitKerja;

  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH);

    axios({
      method: "post",
      url: API_DAFTARHADIR + "/getdh",
      timeout: API_TIMEOUT,
      data: {
        tanggal: data.tanggal,
        opd: unitKerja,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_LIST_DH, "error");
        } else {
          //BERHASIL
          const dataDH = response.data.data;
          dispatchSuccess(dispatch, GET_LIST_DH, dataDH);
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_LIST_DH, "error");
        // alert(error);
      });
  };
};

export const getListDaftarHadirInstansi = (data) => {
  const unitKerja = data.unitKerja;

  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH);

    FIREBASE.database()
      .ref("users")
      .orderByChild("unitKerja")
      .equalTo(unitKerja)
      .once("value", (querySnapshot) => {
        let dataKu = querySnapshot.val();
        dataKu = { ...dataKu };

        if (dataKu) {
          axios({
            method: "post",
            url: API_DAFTARHADIR + "/getdh",
            timeout: API_TIMEOUT,
            data: {
              tanggal: data.tanggal,
              opd: unitKerja,
            },
          })
            .then((response) => {
              if (response.status !== 200) {
                // ERROR
                dispatchError(dispatch, GET_LIST_DH, "error");
              } else {
                //BERHASIL
                const dataDH = response.data.data;

                const dataKirim = Object.keys(dataKu).map((keys) => {
                  let dataFilter = dataDH.filter((filter) => {
                    return dataKu[keys].nama === filter.nama;
                  });

                  const dataSementara = {};
                  dataSementara.nama = dataKu[keys].nama;
                  dataSementara.nip = dataKu[keys].nip;
                  dataSementara.pangkat = dataKu[keys].pangkat;
                  dataSementara.uid = dataKu[keys].uid;
                  dataSementara.avatar = dataKu[keys].avatar
                    ? dataKu[keys].avatar
                    : null;
                  dataSementara.id = dataFilter[0] ? dataFilter[0].id : "";
                  dataSementara.waktuMasuk = dataFilter[0]
                    ? dataFilter[0].waktuMasuk
                    : "";
                  dataSementara.waktuPulang = dataFilter[0]
                    ? dataFilter[0].waktuPulang
                    : "";
                  dataSementara.workingHours = dataFilter[0]
                    ? dataFilter[0].workingHours
                    : "";
                  dataSementara.status = dataFilter[0]
                    ? dataFilter[0].status
                    : "";

                  return dataSementara;
                });

                dispatchSuccess(dispatch, GET_LIST_DH, dataKirim);
              }
            })
            .catch((error) => {
              // ERROR
              dispatchError(dispatch, GET_LIST_DH, "error");
              // alert(error);
            });
        }
      })
      .catch((error) => {
        dispatchError(dispatch, GET_LIST_DH, "error");
        //alert(error);
      });
  };
};

export const getListDaftarHadirInstansi2 = (data) => {
  const unitKerja = data.unitKerja;

  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH);

    if (data) {
      axios({
        method: "post",
        url: API_DAFTARHADIR + "/ambildhoharianpd",
        timeout: API_TIMEOUT,
        data: {
          tanggal: data.tanggal,
          unitKerja: unitKerja,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            // ERROR
            dispatchError(dispatch, GET_LIST_DH, "error");
          } else {
            //BERHASIL
            const dataDH = response.data.data;

            dispatchSuccess(dispatch, GET_LIST_DH, dataDH);
          }
        })
        .catch((error) => {
          // ERROR
          dispatchError(dispatch, GET_LIST_DH, "error");
          // alert(error);
        });
    }
  };
};

export const getListDaftarHadirInstansi2_Shift = (data) => {
  const unitKerja = data.unitKerja;

  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH);

    if (data) {
      axios({
        method: "post",
        url: API_DAFTARHADIR + "/ambildhoharianpd/shift",
        timeout: API_TIMEOUT,
        data: {
          tanggal: data.tanggal,
          unitKerja: unitKerja,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            // ERROR
            dispatchError(dispatch, GET_LIST_DH, "error");
          } else {
            //BERHASIL
            const dataDH = response.data.data;

            dispatchSuccess(dispatch, GET_LIST_DH, dataDH);
          }
        })
        .catch((error) => {
          // ERROR
          dispatchError(dispatch, GET_LIST_DH, "error");
          // alert(error);
        });
    }
  };
};

export const getListDaftarHadirBulanan = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH_BULANAN);

    axios({
      method: "post",
      url: API_DAFTARHADIR + "/getdh/bulanan",
      timeout: API_TIMEOUT,
      data: {
        token: data.token,
        bulan: data.bulan,
        tahun: data.tahun,
        idUser: data.idUser,
      },
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_LIST_DH_BULANAN, "error");
        } else {
          //BERHASIL
          const dataDH = response.data.data;

          dispatchSuccess(dispatch, GET_LIST_DH_BULANAN, dataDH);
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_LIST_DH_BULANAN, "error");
        alert(error);
      });
  };
};

export const getListDaftarHadirMingguan = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH_BULANAN);

    axios({
      method: "post",
      url: API_DAFTARHADIR + "/getdh/mingguan",
      timeout: API_TIMEOUT,
      data: {
        tAwal: data.tAwal,
        tAkhir: data.tAkhir,
        idUser: data.idUser,
      },
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_LIST_DH_BULANAN, "error");
        } else {
          //BERHASIL
          const dataDH = response.data.data;

          dispatchSuccess(dispatch, GET_LIST_DH_BULANAN, dataDH);
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_LIST_DH_BULANAN, "error");
        alert(error);
      });
  };
};

function fixNegativeTime(time) {
  // Ganti semua tanda negatif dengan string kosong
  return time.replace(/-/g, "");
}

// Fungsi untuk mengiterasi array objek dan memperbaiki 'workingHours'
function fixWorkingHours(employees) {
  return employees.map((employee) => {
    // Gunakan spread operator untuk menyalin properti lain dari objek
    return {
      ...employee,
      workingHoursKu: fixNegativeTime(employee.workingHours),
    };
  });
}

export const getListDaftarHadirMingguanShift = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH_BULANAN);

    axios({
      method: "post",
      url: API_DAFTARHADIR + "/getdh/mingguanshift/",
      timeout: API_TIMEOUT,
      data: {
        tAwal: data.tAwal,
        tAkhir: data.tAkhir,
        idUser: data.idUser,
      },
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_LIST_DH_BULANAN, "error");
        } else {
          //BERHASIL
          const dataDH = fixWorkingHours(response.data.data);
          const dataku = fixWorkingHours(dataDH);

          dispatchSuccess(dispatch, GET_LIST_DH_BULANAN, dataku);
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_LIST_DH_BULANAN, "error");
        alert(error);
      });
  };
};

export const getListDaftarHadirInstansiRekapBulan = (data) => {
  const unitKerja = data.unitKerja;

  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH);

    FIREBASE.database()
      .ref("users")
      .orderByChild("unitKerja")
      .equalTo(unitKerja)
      .once("value", (querySnapshot) => {
        let dataKu = querySnapshot.val();
        dataKu = { ...dataKu };

        if (dataKu) {
          axios({
            method: "post",
            url: API_DAFTARHADIR + "/instansi/bulan",
            timeout: API_TIMEOUT,
            data: {
              tahun: data.tahun,
              bulan: data.bulan,
              unitKerja: data.unitKerja,
            },
          })
            .then((response) => {
              if (response.status !== 200) {
                // ERROR
                dispatchError(dispatch, GET_LIST_DH, "error");
              } else {
                //BERHASIL
                const dataDH = response.data.data;

                const dataRekap = Object.keys(dataKu).map((keys) => {
                  const dataA = dataDH.filter((xx) => {
                    return dataKu[keys].uid == xx.idUser;
                  });

                  let hadir = 0;
                  let dinasLuar = 0;
                  let izin = 0;
                  let tugasBelajar = 0;
                  let sakit = 0;
                  let tanpaBerita = 0;
                  let kosong = 0;
                  let izinBelajar = 0;
                  let wh = 0;

                  dataA.map((item) => {
                    var wh1 = item.workingHours ? item.workingHours : 0;
                    var wh2 = wh1 != 0 ? wh1.split(":") : 0;
                    var wh3 =
                      wh2 != 0
                        ? parseFloat(wh2[0]) + parseFloat(wh2[1]) / 60
                        : 0;

                    wh = wh + wh3;

                    switch (item.status) {
                      case "Hadir":
                        hadir = hadir + 1;
                        break;
                      case "Dinas Luar":
                        dinasLuar = dinasLuar + 1;
                        break;
                      case "Izin":
                        izin = izin + 1;
                        break;
                      case "Tugas Belajar":
                        tugasBelajar = tugasBelajar + 1;
                        break;
                      case "Sakit":
                        sakit = sakit + 1;
                        break;
                      case "Tanpa Berita":
                        tanpaBerita = sakit + 1;
                        break;
                      case "Izin Belajar":
                        izinBelajar = izinBelajar + 1;
                        break;
                      case "":
                        kosong = kosong + 1;
                        break;
                    }
                  });

                  let returnData = {};
                  returnData.uid = dataKu[keys].uid;
                  returnData.nama = dataKu[keys].nama;
                  returnData.avatar = dataKu[keys].avatar;
                  returnData.hadir = hadir;
                  returnData.dinasLuar = dinasLuar;
                  returnData.izin = izin;
                  returnData.tugasBelajar = tugasBelajar;
                  returnData.sakit = sakit;
                  returnData.tanpaBerita = tanpaBerita;
                  returnData.izinBelajar = izinBelajar;
                  returnData.kosong = kosong;
                  returnData.workingHours = wh.toFixed(2);
                  return returnData;
                });
                dataRekap.sort((a, b) => b.workingHours - a.workingHours);
                dispatchSuccess(dispatch, GET_LIST_DH, dataRekap);
              }
            })
            .catch((error) => {
              // ERROR
              dispatchError(dispatch, GET_LIST_DH, "error");
              // alert(error);
            });
        }
      })
      .catch((error) => {
        dispatchError(dispatch, GET_LIST_DH, "error");
        //alert(error);
      });
  };
};

export const getListDaftarHadirInstansiRekapMinggu = (data) => {
  const unitKerja = data.unitKerja;

  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH);

    FIREBASE.database()
      .ref("users")
      .orderByChild("unitKerja")
      .equalTo(unitKerja)
      .once("value", (querySnapshot) => {
        let dataKu = querySnapshot.val();
        dataKu = { ...dataKu };

        if (dataKu) {
          axios({
            method: "post",
            url: API_DAFTARHADIR + "/instansi/minggu",
            timeout: API_TIMEOUT,
            data: {
              tAwal: data.tAwal,
              tAkhir: data.tAkhir,
              unitKerja: data.unitKerja,
            },
          })
            .then((response) => {
              if (response.status !== 200) {
                // ERROR
                dispatchError(dispatch, GET_LIST_DH, "error");
              } else {
                //BERHASIL
                const dataDH = response.data.data;

                const dataRekap = Object.keys(dataKu).map((keys) => {
                  const dataA = dataDH.filter((xx) => {
                    return dataKu[keys].uid == xx.idUser;
                  });

                  let hadir = 0;
                  let dinasLuar = 0;
                  let izin = 0;
                  let tugasBelajar = 0;
                  let sakit = 0;
                  let tanpaBerita = 0;
                  let kosong = 0;
                  let cuti = 0;
                  let izinBelajar = 0;
                  let wh = 0;

                  dataA.map((item) => {
                    if (item.status == "Dinas Luar") {
                      const dateObj = new Date(item.tanggal);
                      const dayOfWeek = dateObj.getUTCDay();

                      // Mendapatkan waktu kerja dari hari yang sesuai dalam format "jam:menit"
                      const waktuKerja = WAKTU_KERJA5[dayOfWeek] || "0:00";
                      item.workingHours = waktuKerja;
                    }

                    var wh1 = hitungSelisihWaktu(
                      item.waktuMasuk,
                      item.waktuPulang
                    );

                    var wh2 = wh1 != 0 ? wh1.split(":") : 0;
                    var wh3 =
                      wh2 != 0
                        ? parseFloat(wh2[0]) + parseFloat(wh2[1]) / 60
                        : 0;

                    wh = wh + wh3;

                    switch (item.status) {
                      case "Hadir":
                        hadir = hadir + 1;
                        break;
                      case "Dinas Luar":
                        dinasLuar = dinasLuar + 1;
                        break;
                      case "Izin":
                        izin = izin + 1;
                        break;
                      case "Tugas Belajar":
                        tugasBelajar = tugasBelajar + 1;
                        break;
                      case "Sakit":
                        sakit = sakit + 1;
                        break;
                      case "Tanpa Berita":
                        tanpaBerita = sakit + 1;
                        break;
                      case "Izin Belajar":
                        izinBelajar = izinBelajar + 1;
                        break;
                      case "":
                        kosong = kosong + 1;
                        break;
                      case "Cuti":
                        cuti = cuti + 1;
                        break;
                    }
                  });

                  let returnData = {};
                  returnData.uid = dataKu[keys].uid;
                  returnData.nama = dataKu[keys].nama;
                  returnData.avatar = dataKu[keys].avatar;
                  returnData.statusDHO = dataKu[keys].statusDHO
                    ? dataKu[keys].statusDHO
                    : "reguler";
                  returnData.hadir = hadir;
                  returnData.dinasLuar = dinasLuar;
                  returnData.izin = izin;
                  returnData.tugasBelajar = tugasBelajar;
                  returnData.sakit = sakit;
                  returnData.tanpaBerita = tanpaBerita;
                  returnData.izinBelajar = izinBelajar;
                  returnData.kosong = kosong;
                  returnData.cuti = cuti;
                  returnData.workingHours = wh.toFixed(2);
                  return returnData;
                });

                dataRekap.sort((a, b) => b.workingHours - a.workingHours);

                const filterDataRekap = dataRekap.filter((item) => {
                  return item.statusDHO === "reguler";
                });

                dispatchSuccess(dispatch, GET_LIST_DH, filterDataRekap);
              }
            })
            .catch((error) => {
              // ERROR
              dispatchError(dispatch, GET_LIST_DH, "error");
              // alert(error);
            });
        }
      })
      .catch((error) => {
        dispatchError(dispatch, GET_LIST_DH, "error");
        //alert(error);
      });
  };
};

export const getListDaftarHadirInstansiReguler = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH);

    axios({
      method: "post",
      url: API_DAFTARHADIR + "/instansi/reguler",
      timeout: API_TIMEOUT,
      data: {
        tAwal: data.tAwal,
        tAkhir: data.tAkhir,
        unitKerja: data.unitKerja,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_LIST_DH, "error");
        } else {
          //BERHASIL
          const dataDH = response.data.data;

          dispatchSuccess(dispatch, GET_LIST_DH, dataDH);
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_LIST_DH, "error");
        // alert(error);
      });
  };
};

export const getListDaftarHadirInstansiShift = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH);

    axios({
      method: "post",
      url: API_DAFTARHADIR + "/instansi/shift",
      timeout: API_TIMEOUT,
      data: {
        tAwal: data.tAwal,
        tAkhir: data.tAkhir,
        unitKerja: data.unitKerja,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_LIST_DH, "error");
        } else {
          //BERHASIL
          const dataDH = response.data.data;
          console.log(dataDH);
          dispatchSuccess(dispatch, GET_LIST_DH, dataDH);
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_LIST_DH, "error");
        // alert(error);
      });
  };
};
