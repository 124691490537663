export function capitalizeWords(str) {
  return str
    .split(/(\s|\,|\.)/) // Pisahkan string berdasarkan spasi, koma, atau titik
    .map((word) => {
      // Kapitalisasi huruf pertama dari setiap kata dan setelah setiap tanda baca (, .)
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
}

export function removeCommas(str) {
  return str.replace(/,/g, "");
}

export function hitungSelisihWaktu(waktuMasuk, waktuPulang) {
  // Mengubah waktu ke objek Date
  if (waktuMasuk !== "" && waktuPulang !== "") {
    const waktuAwal = new Date(`1970-01-01T${waktuMasuk}:00`);
    const waktuAkhir = new Date(`1970-01-01T${waktuPulang}:00`);

    // Menghitung selisih dalam milidetik dan konversi ke menit
    const selisih = (waktuAkhir - waktuAwal) / 1000 / 60;

    // Menghitung jam dan menit
    const jam = Math.floor(selisih / 60);
    const menit = selisih % 60;

    // Mengembalikan waktu dalam format "hh:mm"
    return `${jam.toString().padStart(2, "0")}:${menit
      .toString()
      .padStart(2, "0")}`;
  } else {
    return "00:00";
  }
}
