import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
  FormGroup,
  Input,
  CardFooter,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import { GET_TOKEN, GET_INSTANSI } from "utils";
import FIREBASE from "config/FIREBASE";
import swal from "sweetalert";
import axios from "axios";
import { logOutUser } from "actions/AuthAction";
import jwt_decode from "jwt-decode";
import { dateTime } from "utils";
import {
  getListDaftarHadir,
  getListDaftarHadirInstansi,
} from "actions/DaftarHadirAction";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Avatar from "../../assets/img/default-avatar.png";

import AccountList from "./komponenListAkun";

export default function ListAkun() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getListDHLoading, getListDHResult, getListDHError } = useSelector(
    (state) => state.DaftarHadirReducer
  );

  // auth

  const [token, setToken] = useState("");
  const [nama, setNama] = useState("");
  const [username, setUsername] = useState("");
  const [akses, setAkses] = useState("");
  const [expire, setExpire] = useState("");
  const [idAdmin, setIdAdmin] = useState("1");
  const [accessToken, setAccessToken] = useState("");
  const [hariIni, sethariIni] = useState("");
  const [labelBulan, setLabelBulan] = useState("");
  const [bulan, setBulan] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [tahun, setTahun] = useState("");
  const [listInstansi, setListInstansi] = useState([]);

  const [instansi, setInstansi] = useState("");

  const [listAkuns, setListAkuns] = useState(false);
  const [loadingListAkun, setLoadingListAkun] = useState(false);

  const [hadir, sethadir] = useState(0);
  const [dinasLuar, setDinasLuar] = useState(0);
  const [izin, setIzin] = useState(0);
  const [tugasBelajar, setTugasBelajar] = useState(0);
  const [sakit, setSakit] = useState(0);

  const [kosong, setKosong] = useState(0);
  const [dateTimeKu, setDateTimeKu] = useState("");
  const [status, setStatus] = useState("");

  const getTime = async () => {
    //BERHASIL

    const response = await axios.get("https://worldtimeapi.org/api/ip");
    const data = response.data;
    setDateTimeKu(data.datetime);
  };

  useEffect(() => {
    getDetailAdmin();
  }, []);

  const getDetailAdmin = async () => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    setInstansi(user.unitKerja);

    setStatus(user.status);
  };

  useEffect(() => {
    if (instansi) {
      setLoadingListAkun(true);
      getAkuns(instansi);
    }
  }, [instansi]);

  useEffect(() => {
    if (dateTimeKu) {
      getTanggal(new Date(dateTimeKu));
    }
  }, [dateTimeKu]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + "/token");
      setToken(response.data);
      const decoded = jwt_decode(response.data.accessToken);
      setNama(decoded.nama);
      setUsername(decoded.username);
      setAccessToken(response.data.accessToken);
      setAkses(decoded.akses.split(","));
      setExpire(decoded.exp);
      setIdAdmin(decoded.idAdmin);
    } catch (error) {
      if (error.response) {
        dispatch(logOutUser(history));
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(GET_TOKEN + "/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setNama(decoded.nama);
        setUsername(decoded.username);
        setAkses(decoded.akses);
        setIdAdmin(decoded.idAdmin);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //auth

  const getListInstansi = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_INSTANSI);
      data1 = response.data.data;

      if (data1.length > 0) {
        const dataInstansi = data1.map((item) => {
          return item.unitKerja;
        });
        setListInstansi(dataInstansi);
      } else {
        setListInstansi([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const getAkuns = async (instansi) => {
    var data1 = [];

    FIREBASE.database()
      .ref("users")
      .orderByChild("unitKerja")
      .equalTo(instansi)
      .once("value", (querySnapshot) => {
        let dataKu = querySnapshot.val();
        dataKu = { ...dataKu };

        if (dataKu) {
          setListAkuns(dataKu);
          setLoadingListAkun(false);
        } else {
          setLoadingListAkun(false);
          setListAkuns([]);
        }
      })
      .catch((error) => {
        //alert(error);
        setListAkuns([]);
      });
  };

  //dari smart service

  const getTanggal = (date) => {
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = "0" + month;
    }
    var day = new Date().getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }
    var year = new Date().getFullYear().toString();
    var tanggalKu = year + "-" + month + "-" + day;

    let hariIniKu = dateTime(new Date());

    sethariIni(hariIniKu);
    setLabelBulan(month);
    setTahun(year);

    if (month.length == 1) {
      month = "0" + month;
    }

    setBulan(month);

    if (day.length === 1) {
      day = "0" + day;
    }
    var tanggalKu = year + "-" + month + "-" + day;

    setTanggal(tanggalKu);
  };

  useEffect(() => {
    if (getListDHResult) {
      let hadir = 0;
      let dinasLuar = 0;
      let izin = 0;
      let tugasBelajar = 0;
      let sakit = 0;
      let tanpaBerita = 0;
      let kosong = 0;

      getListDHResult.map((item) => {
        switch (item.status) {
          case "Hadir":
            hadir = hadir + 1;
            break;
          case "Dinas Luar":
            dinasLuar = dinasLuar + 1;
            break;
          case "Izin":
            izin = izin + 1;
            break;
          case "Tugas Belajar":
            tugasBelajar = tugasBelajar + 1;
            break;
          case "Sakit":
            sakit = sakit + 1;
            break;
          case "Tanpa Berita":
            tanpaBerita = sakit + 1;
            break;
          case "":
            kosong = kosong + 1;
            break;
        }
      });
      sethadir(hadir);
      setDinasLuar(dinasLuar);
      setIzin(izin);
      setSakit(sakit);
      setTugasBelajar(tugasBelajar);
      setKosong(kosong);
    }
  }, [getListDHResult]);

  useEffect(() => {
    if (tanggal && instansi) {
      const data = { tanggal: tanggal, unitKerja: instansi };
      dispatch(getListDaftarHadirInstansi(data));
    }
  }, [instansi, tanggal]);

  //dari smart service

  useEffect(() => {
    getListInstansi();

    getTime();
    const user = JSON.parse(window.localStorage.getItem("user"));
    setInstansi(user.unitKerja);
  }, []);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          {" "}
          {listAkuns ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                flexDirection: "row",
              }}
            >
              <AccountList akunList={listAkuns} statusLogin={status} />
            </div>
          ) : (
            <Spinner color="primary" />
          )}
        </Col>
      </Row>
    </div>
  );
}
