import { combineReducers } from 'redux';
import LigaReducer from './liga';
import JerseyReducer from './jersey';
import AuthReducer from './auth';
import PesananReducer from './pesanan';
import BeritaReducer from './berita';
import DestinasiReducer from './destinasi';
import ProdukReducer from './produk';
import VideoReducer from './video';
import LaporanReducer from './laporan';
import FasilitasDestinasiReducer from './fasilitasdestinasi';
import NotifikasiReducer from './notifikasi';
import TokoReducer from './toko';
import PesananAllReducer from './pesananMasuk';
import GetUserReducer from './getuserku';
import ServiceReducer from './service';
import AngkutSampahReducer from './angkutSampah';
import BannerReducer from './banner';
import EmergencyReducer from './emergency';
import AdminReducer from './admin';
import PasarTernakReducer from './pasarternak';
import DaftarHadirReducer from './daftarhadir';

export default combineReducers({
  LigaReducer,
  JerseyReducer,
  AuthReducer,
  PesananReducer,
  BeritaReducer,
  DestinasiReducer,
  ProdukReducer,
  VideoReducer,
  LaporanReducer,
  FasilitasDestinasiReducer,
  NotifikasiReducer,
  TokoReducer,
  PesananAllReducer,
  GetUserReducer,
  ServiceReducer,
  AngkutSampahReducer,
  BannerReducer,
  EmergencyReducer,
  AdminReducer,
  PasarTernakReducer,
  DaftarHadirReducer,
});
