import React from "react";
import { Link } from "react-router-dom";
import phoneIconUrl from "../../assets/img/phone.png";
import emailIconUrl from "../../assets/img/email.png";
import { capitalizeWords } from "utils/function";

// Data akun contoh

const cardStyle = {
  width: "600px",
  borderRadius: "10px",
  overflow: "hidden",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  margin: "20px",
  display: "flex",
  alignItems: "center",
  background: "linear-gradient(to right, #6dd5ed, #2193b0)",
  position: "relative",
};

const profilePicStyle = {
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  margin: "15px",
  border: "3px solid white", // Border putih untuk foto profil
};

const cardInfoStyle = {
  padding: "15px",
  color: "white", // Warna teks putih
};

const h2Style = {
  margin: "0 0 10px 0",
  fontSize: "1.4em",
};

const pStyle = {
  margin: "4px 0",
  fontSize: "1em",
};

const accountListStyle = {
  display: "flex",
  flexWrap: "wrap",
};

const editButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  padding: "5px 10px",
  background: "#fff",
  color: "#333",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

const phoneInfoStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: "0.9em",
  marginTop: "5px",
};

const phoneIconStyle = {
  width: "20px", // Atur ukuran ikon
  marginRight: "5px",
};
// Komponen Kartu Akun
const UserCard = ({
  nama,
  avatar,
  unitKerja,
  status,
  pangkat,
  nip,
  uid,
  nohp,
  email,
  statusLogin,
}) => (
  <div style={cardStyle}>
    <img src={avatar} alt="Profile" style={profilePicStyle} />
    <div style={cardInfoStyle}>
      <h2 style={h2Style}>{capitalizeWords(nama)}</h2>
      <p style={pStyle}>{capitalizeWords(unitKerja)}</p>
      <p style={pStyle}>{status}</p>
      <p style={pStyle}>{pangkat}</p>
      <p style={pStyle}>{nip}</p>
      <div style={phoneInfoStyle}>
        <img src={phoneIconUrl} alt="Phone" style={phoneIconStyle} />{" "}
        {/* Menggunakan gambar ikon */}
        <span>{nohp}</span>
      </div>
      <div style={phoneInfoStyle}>
        <img src={emailIconUrl} alt="Phone" style={phoneIconStyle} />{" "}
        <span>{email}</span>
      </div>
    </div>
    {statusLogin === "admin" || statusLogin === "super admin" ? (
      <EditButton uid={uid} />
    ) : (
      <></>
    )}
  </div>
);

// Komponen Utama
const AccountList = ({ akunList, statusLogin }) => {
  return (
    <div style={accountListStyle}>
      {Object.keys(akunList).map((account, index) => (
        <UserCard
          key={index}
          {...akunList[account]}
          statusLogin={statusLogin}
        />
      ))}
    </div>
  );
};
const EditButton = ({ uid }) => (
  <Link to={`/admin/listakun/edit/${uid}`} style={{ textDecoration: "none" }}>
    <button style={editButtonStyle}>Edit</button>
  </Link>
);

export default AccountList;
