/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation } from "react-router-dom";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import { connect } from "react-redux";
import { checkLogin } from "actions/AuthAction";

var ps;

function Dashboard(props) {
  const [backgroundColor, setBackgroundColor] = React.useState("white");
  const [activeColor, setActiveColor] = React.useState("info");
  const mainPanel = React.useRef();
  const location = useLocation();

  const [routesKu, setRoutesKu] = useState([]);
  const [status, setStatus] = useState("");
  const [unitKerja, setUnitKerja] = useState("");

  React.useEffect(() => {
    props.dispatch(checkLogin(props.history));

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  });

  React.useEffect(() => {
    getDetailAdmin();
  }, []);

  const getDetailAdmin = async () => {
    const user = JSON.parse(window.localStorage.getItem("user"));

    setUnitKerja(user.unitKerja);
    setStatus(user.status);
  };

  function checkValueInArray(array, value) {
    return array.includes(value);
  }

  React.useEffect(() => {
    if (status === "super admin") {
      var routesTemp = routes.filter((item) => {
        const akses = item.akses;
        const aksesKu = akses.split(",");
        const check = checkValueInArray(aksesKu, "super admin");
        return check;
      });

      setRoutesKu(routesTemp);
    } else if (status == "admin") {
      if (unitKerja === "RUMAH SAKIT UMUM DAERAH BAA") {
        var routesTemp = routes.filter((item) => {
          const akses = item.akses;
          const aksesKu = akses.split(",");
          const check = checkValueInArray(aksesKu, "admin");
          return check;
        });
      } else {
        var routesTemp = routes.filter((item) => {
          const akses = item.akses;
          const aksesKu = akses.split(",");
          const check = checkValueInArray(aksesKu, "admin");
          const checkShift = checkValueInArray(aksesKu, "shift");
          return check && !checkShift;
        });
      }
      setRoutesKu(routesTemp);
    } else if (status == "iphone") {
      var routesTemp = routes.filter((item) => {
        const akses = item.akses;
        const aksesKu = akses.split(",");
        const check = checkValueInArray(aksesKu, "iphone");
        return check;
      });

      setRoutesKu(routesTemp);
    } else if (status == "user") {
      var routesTemp = routes.filter((item) => {
        const akses = item.akses;
        const aksesKu = akses.split(",");
        const check = checkValueInArray(aksesKu, "user");
        return check;
      });

      setRoutesKu(routesTemp);
    }
  }, [status]);

  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routesKu}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel" ref={mainPanel}>
        <DemoNavbar {...props} />
        <Switch>
          {routesKu.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
                exact
              />
            );
          })}
        </Switch>
        <Footer fluid />
      </div>
    </div>
  );
}

export default connect()(Dashboard);
