import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
  FormGroup,
  Input,
  CardFooter,
  Badge,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import { DELETE_EMERGENCY, GET_TOKEN, API_TIMEOUT, GET_INSTANSI } from "utils";

import swal from "sweetalert";
import axios from "axios";
import { logOutUser } from "actions/AuthAction";
import jwt_decode from "jwt-decode";
import { dateTime } from "utils";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getUserStatusRegister,
  getUserByKategori,
} from "actions/GetUserAction";
import Avatar from "../../assets/img/default-avatar.png";

import { removeCommas } from "utils/function";
import { capitalizeWords } from "utils/function";

export default function AllASN() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getListUserLoading, getListUserResult, getListUserError } =
    useSelector((state) => state.GetUserReducer);

  // auth

  const [token, setToken] = useState("");
  const [nama, setNama] = useState("");
  const [username, setUsername] = useState("");
  const [akses, setAkses] = useState("");
  const [expire, setExpire] = useState("");
  const [idAdmin, setIdAdmin] = useState("1");
  const [accessToken, setAccessToken] = useState("");
  const [hariIni, sethariIni] = useState("");
  const [labelBulan, setLabelBulan] = useState("");
  const [bulan, setBulan] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [tahun, setTahun] = useState("");
  const [listInstansi, setListInstansi] = useState([]);
  const [instansi, setInstansi] = useState("ALL");

  const [statusUser, setStatusUser] = useState("ASN");
  const [avatarBlob, setAvatarBlob] = useState();

  const [statusPegawai, setStatusPegawai] = useState("all");
  const [statusDHO, setStatusDHO] = useState("all");

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + "/token");
      setToken(response.data);
      const decoded = jwt_decode(response.data.accessToken);
      setNama(decoded.nama);
      setUsername(decoded.username);
      setAccessToken(response.data.accessToken);
      setAkses(decoded.akses.split(","));
      setExpire(decoded.exp);
      setIdAdmin(decoded.idAdmin);
    } catch (error) {
      if (error.response) {
        dispatch(logOutUser(history));
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  function convertToYYYYMMDD(dateString) {
    if (!dateString) {
      return "";
    }
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    var day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // Contoh penggunaan:
  var originalDate = "1976-12-16T00:40:00.000Z";
  var convertedDate = convertToYYYYMMDD(originalDate);

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(GET_TOKEN + "/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setNama(decoded.nama);
        setUsername(decoded.username);
        setAkses(decoded.akses);
        setIdAdmin(decoded.idAdmin);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //auth

  const getListInstansi = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_INSTANSI);
      data1 = response.data.data;

      if (data1.length > 0) {
        const dataInstansi = data1.map((item) => {
          return item.unitKerja;
        });
        setListInstansi(dataInstansi);
      } else {
        setListInstansi([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const [allASN, setAllASN] = useState();
  const [listAllASN, setListAllASN] = useState();
  const getaAllASN = async () => {
    var data1 = [];

    try {
      const response = await axios.get(
        "https://smartservice.rotendaokab.go.id/api/admin/asnuser"
      );
      data1 = response.data.data;
      setListAllASN(data1);
      setAllASN(data1);

      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      setAllASN([]);
      console.error(error);
    }
  };

  //dari smart service

  const getTanggal = () => {
    var date = new Date();
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = "0" + month;
    }
    var day = new Date().getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }
    var year = new Date().getFullYear().toString();
    var tanggalKu = year + "-" + month + "-" + day;

    let hariIniKu = dateTime(new Date());

    sethariIni(hariIniKu);

    setLabelBulan(month);

    setTahun(year);

    if (month.length == 1) {
      month = "0" + month;
    }

    setBulan(month);

    if (day.length === 1) {
      day = "0" + day;
    }
    var tanggalKu = year + "-" + month + "-" + day;

    setTanggal(tanggalKu);
  };

  useEffect(() => {
    if (listAllASN) {
      const filter = listAllASN.filter((item) => {
        // Check for unitKerja based on instansi
        const unitKerjaMatches =
          instansi === "ALL"
            ? item.unitKerja !== ""
            : item.unitKerja === instansi;

        // Check for statusDHO
        const statusDHOMatches =
          statusDHO === "all"
            ? item.statusDHO !== ""
            : item.statusDHO === statusDHO;

        // Check for statusPegawai
        const statusPegawaiMatches =
          statusPegawai === "all"
            ? item.statuspegawai !== null
            : statusPegawai === "aktif"
            ? item.statuspegawai === "aktif" || item.statuspegawai === ""
            : item.statuspegawai === statusPegawai;

        return unitKerjaMatches && statusDHOMatches && statusPegawaiMatches;
      });
      setAllASN(filter);
    }
  }, [instansi, statusDHO, statusPegawai]);

  //dari smart service

  useEffect(() => {
    getTanggal();
    getListInstansi();
    getaAllASN();

    //refreshToken();
  }, []);

  // useEffect(() => {
  //   dispatch(getUserStatusRegister({ statusRegister: statusUser }));
  // }, [statusUser]);

  // UPLOAD GAMBAR AVATAR KE SISTEM

  // const convertToDataURI = () => {
  //   // Membuat elemen Image baru
  //   const img = new Image();

  //   // Menetapkan sumber gambar ke Avatar yang diimpor
  //   img.src = Avatar;

  //   // Menunggu gambar dimuat sebelum mengonversi
  //   img.onload = () => {
  //     // Membuat canvas
  //     const canvas = document.createElement("canvas");
  //     canvas.width = img.width;
  //     canvas.height = img.height;

  //     // Menggambar gambar ke canvas
  //     const ctx = canvas.getContext("2d");
  //     ctx.drawImage(img, 0, 0);

  //     // Mengonversi canvas ke Data URI
  //     const dataURI = canvas.toDataURL();

  //     // Mengatur Data URI ke state (atau Anda bisa melakukan apa saja dengan dataURI ini)
  //     setDataUri(dataURI);
  //   };
  // };

  // function ambilgambarKu() {
  //   dispatch(getUserStatusRegister({ statusRegister: statusUser }));
  // }

  // function dataURItoBlob(dataURI) {
  //   const byteString = atob(dataURI.split(",")[1]);
  //   const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  //   const ab = new ArrayBuffer(byteString.length);
  //   const ia = new Uint8Array(ab);
  //   for (let i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }
  //   return new Blob([ab], { type: mimeString });
  // }

  // const handleSetAvatar = () => {
  //   console.log(awalKu);
  //   console.log(akhirKu);
  //   setProses(true);

  //   const awal = awalKu;
  //   const akhir = akhirKu;

  //   let resultArray = [];

  //   const keys = Object.keys(getListUserResult);
  //   keys.forEach((key, index) => {
  //     if (index >= awal && index < akhir) {
  //       const user = getListUserResult[key];
  //       if (user.uid) {
  //         // Memastikan uid dan avatar ada
  //         resultArray.push({
  //           uid: user.uid, // Pastikan properti ini yang Anda inginkan
  //           avatar: user.avatar ? user.avatar : dataUri,
  //         });
  //       }
  //     }
  //   });

  //   console.log(resultArray);
  //   handleUploadAvatar(resultArray);
  // };

  // const handleUploadAvatar = async (listAvatar) => {
  //   const formData = new FormData();

  //   // for (let i = 0; i < listAvatar.length; i++) {
  //   //   formData.append(`images[${i}]`, listAvatar[i].avatar);
  //   //   formData.append(`customFileNames[${i}]`, listAvatar[i].nama);
  //   // }

  //   for (let i = 0; i < listAvatar.length; i++) {
  //     // Mengonversi Data URI ke Blob

  //     const imageBlob = dataURItoBlob(listAvatar[i].avatar);
  //     formData.append(`images[${i}]`, imageBlob, listAvatar[i].uid + `.jpg`); // Menambahkan Blob ke FormData
  //     // Asumsi selectedFiles[i].name tersedia dan berisi nama file
  //     formData.append(`customFileNames[${i}]`, listAvatar[i].uid);
  //   }

  //   try {
  //     const response = await axios.post(
  //       "https://smartdh.rotendaokab.go.id/api/post/profile",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     setProses(false);
  //     console.log("Server Response:", response.data);
  //   } catch (error) {
  //     setProses(false);
  //     console.error("Upload Error:", error);
  //   }
  // };

  // UPLOAD GAMBAR AVATAR KE SISTEM

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Smart User {statusUser}
                {statusUser == "ASN  " ? instansi : ""}{" "}
                <Badge color="primary"> {allASN && allASN.length}</Badge>
                users
              </CardTitle>
              {/* <div>
                <button onClick={convertToDataURI}>
                  Convert Avatar to Data URI
                </button>
                {dataUri && (
                  <div>
                    <img src={dataUri} alt="Converted Avatar" />
                    <p>Data URI: {dataUri.substring(0, 30)}...</p>{" "}
                   
                  </div>
                )}
              </div> */}
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                {statusUser == "ASN" && (
                  <Col md={3}>
                    <FormGroup>
                      <label>Instansi</label>
                      <Input
                        type="select"
                        value={instansi}
                        name="nama"
                        onChange={(event) => setInstansi(event.target.value)}
                      >
                        <option value={"ALL"}> ALL</option>
                        {listInstansi.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                )}
                <Col md={3}>
                  <FormGroup>
                    <label>Status Pegawai</label>
                    <Input
                      type="select"
                      value={statusPegawai}
                      name="nama"
                      onChange={(event) => setStatusPegawai(event.target.value)}
                    >
                      <option value={"all"} key={1}>
                        {"All"}
                      </option>
                      <option value={"aktif"} key={2}>
                        {"Aktif"}
                      </option>
                      <option value={"tidak aktif"} key={3}>
                        {"Tidak aktif"}
                      </option>
                      <option value={"meninggal"} key={4}>
                        {"Meninggal"}
                      </option>
                      <option value={"pindah keluar kabupaten"} key={4}>
                        {"Pindah Keluar Kabupaten"}
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <label>Status DHO</label>
                    <Input
                      type="select"
                      value={statusDHO}
                      name="nama"
                      onChange={(event) => setStatusDHO(event.target.value)}
                    >
                      <option value={"all"}> All</option>
                      <option value={"reguler"} key={1}>
                        {"reguler"}
                      </option>
                      <option value={"shift"} key={2}>
                        {"shift"}
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <ReactHTMLTableToExcel
                    className="btn"
                    table="asnakun"
                    filename={"export asnakun " + tanggal}
                    sheet="Data "
                    buttonText="Export to Exl"
                  />
                </Col>

                {/* <Input
                  type="number"
                  value={awalKu}
                  onChange={(e) => setAwalKu(e.target.value)}
                />

                <Input
                  type="number"
                  value={akhirKu}
                  onChange={(e) => setAkhirKu(e.target.value)}
                />
                {proses ? (
                  <Spinner color="primary"> Loading....</Spinner>
                ) : (
                  <></>
                )}
                <Button onClick={handleSetAvatar}>Set Avatar</Button>
                <Button onClick={ambilgambarKu}>ambil Avatar</Button> */}
              </Row>
            </CardHeader>
            <CardBody style={{ overflowX: "auto" }}>
              <Table id="asnakun" style={{ overflowX: "auto" }}>
                <thead className="text-primary">
                  <tr>
                    <th>id</th>
                    <th>Profile</th>
                    <th>nama</th>
                    <th>nip</th>
                    <th>status pegawai</th>
                    <th>status DHO</th>
                    <th>gender</th>
                    <th>nohp</th>
                    <th>pangkat</th>
                    <th>unitKerja</th>
                    <th>dob</th>
                    <th>desa</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allASN ? (
                    allASN.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <img
                            src={
                              "https://smartdh.rotendaokab.go.id/uploads/" +
                                item.uid +
                                ".jpg" || Avatar
                            }
                            alt={"avatar"}
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: 25,
                              borderWidth: 8,
                              borderColor: "red",
                            }}
                          />
                        </td>

                        <td>{item.nama}</td>

                        <td>{removeCommas(item.nip)}</td>
                        <td>
                          <Badge
                            color={
                              item.statuspegawai
                                ? item.statuspegawai == "aktif"
                                  ? "success"
                                  : "danger"
                                : "success"
                            }
                            size="md"
                          >
                            {item.statuspegawai ? item.statuspegawai : "aktif"}
                          </Badge>
                        </td>
                        <td>
                          <Badge
                            color={
                              item.statusDHO
                                ? item.statusDHO == "shift"
                                  ? "danger"
                                  : "primary"
                                : "primary"
                            }
                            size="md"
                          >
                            {item.statusDHO ? item.statusDHO : "reguler"}
                          </Badge>
                        </td>
                        <td>{item.gender} </td>
                        <td>{"0" + item.nohp} </td>
                        <td>{item.pangkat} </td>
                        <td>{capitalizeWords(item.unitKerja)} </td>
                        <td>{convertToYYYYMMDD(item.dob)} </td>
                        <td>{item.desa} </td>
                        <td>
                          <Link
                            className="btn btn-warning"
                            to={"/admin/listakun/edit/" + item.uid + ",all"}
                          >
                            Detail
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" align="center">
                        <Spinner color="primary" /> Loading....
                      </td>
                    </tr>
                  )}
                  {/* {getListUserResult ? (
                    Object.keys(getListUserResult).map((key, index) => (
                      <tr key={key}>
                        <td>{index}</td>
                        <td>{getListUserResult[key].uid}</td>
                        <td>{getListUserResult[key].nama}</td>

                        <td>{getListUserResult[key].alamat}</td>

                        <td>
                          <img
                            src={
                              getListUserResult[key].avatar
                                ? getListUserResult[key].avatar
                                : Avatar
                            }
                            alt={"avatar"}
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: 25,
                              borderWidth: 8,
                              borderColor: "red",
                            }}
                          />
                        </td>

                        <td>{getListUserResult[key].domisili} </td>
                        <td>{getListUserResult[key].nohp} </td>
                        <td>{getListUserResult[key].gender} </td>
                        <td>{getListUserResult[key].namaPengguna} </td>
                        <td>{getListUserResult[key].nik} </td>
                        <td>{getListUserResult[key].nip} </td>

                        <td>{getListUserResult[key].pangkat} </td>
                        <td>{getListUserResult[key].status} </td>
                        <td>{getListUserResult[key].statusRegister} </td>
                        <td>{getListUserResult[key].token} </td>
                        <td>{getListUserResult[key].unitKerja} </td>
                        <td>{getListUserResult[key].email} </td>
                        <td>{getListUserResult[key].desa} </td>
                        <td>{""} </td>
                        <td>{""} </td>
                      </tr>
                    ))
                  ) : getListUserLoading ? (
                    <tr>
                      <td colSpan="6" align="center">
                        <Spinner color="primary" /> Loading....
                      </td>
                    </tr>
                  ) : getListUserError ? (
                    <tr>
                      <td colSpan="6" align="center">
                        {getListUserError}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="6" align="center">
                        Data Kosong
                      </td>
                    </tr>
                  )} */}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
