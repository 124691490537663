import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Progress,
  InputGroup,
  InputGroupAddon,
  Spinner,
  Badge,
} from "reactstrap";

import axios from "axios";
import { useState, useEffect } from "react";
import { API_TIMEOUT } from "utils";

import { capitalizeWords } from "utils/function";

import FIREBASE from "config/FIREBASE";
import swal from "sweetalert";
//import { logApp } from "utils/func";
import { useHistory } from "react-router-dom";
//import { refreshToken } from "utils/func";
import { GET_INSTANSI } from "utils";
import { tanggalLengkap } from "utils";
import { getYearsFrom2022 } from "utils";
import { getCurrentYearAsString } from "utils";

function Libur() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);

  const [listLibur, setListLibur] = useState([]);
  const [libur, setLibur] = useState("");
  const [tanggal, setTanggal] = useState("");

  const [idDelete, setIdDelete] = useState("");
  const [tahun, setTahun] = useState([]);
  const [listTahun, setListTahun] = useState([]);

  const [currentUser, setCurrentUser] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const otorisasi = [
    "eUDelwmRj4f1p0hZ7bf7FsGP5iC3",
    "8sEUvQyGm4X8R8tZ9h2evmWvo363",
  ];

  const [statusOtorisasi, setStatusOtorisasi] = useState(false);
  const statusOt = (uid, otorisasi) => {
    // Fungsi ini akan mengembalikan true jika ada elemen yang cocok dengan 'input'
    return otorisasi.some((element) => element === uid);
  };
  const history = useHistory();

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        //  logApp(user.uid, "User Manek");
        setCurrentUser(user);
        setStatusOtorisasi(statusOt(user.uid, otorisasi));
      } else {
        history.push("/login");
      }
    }
    setListTahun(getYearsFrom2022());
    setTahun(getCurrentYearAsString());
    // refreshToken(history, setAccessToken);
  }, []);

  useEffect(() => {
    if (currentUser) {
      // ambilUsers();
      // ambildatauser();
      ambilLibur();
      // ambilPerangkatDaerah();
    }
  }, [currentUser, tahun]);

  const handleSimpan = () => {
    if (!libur || !tanggal) {
      swal("Gagal", "Data yang anda masukan harus lengkap", "error");
      return;
    }
    setSaveLoading(true);
    simpanLibur();
  };

  function formatDateToString(date) {
    const tanggal = new Date(date);
    return tanggal.toISOString().substring(0, 10); // Mengubah date ke ISO string dan memotong pada karakter ke-10
  }

  const simpanLibur = (id) => {
    const data = {};
    data.libur = libur;

    data.tanggal = formatDateToString(tanggal);

    axios({
      method: "post",
      url: "https://smartservice.rotendaokab.go.id/api/admin/libur/",
      timeout: API_TIMEOUT,
      credentials: "include",
      data: data,
      headers: {
        Authorization: `Bearer ${""}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          setSaveLoading(false);
          setModalOpen(false);
          ambilLibur();
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilLibur = (id) => {
    setListLibur([]);
    axios({
      method: "get",
      url: "https://smartservice.rotendaokab.go.id/api/admin/libur/" + tahun,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${""}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setListLibur(data);
          //setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const onCloseModal = () => {
    setLibur("");
    setTanggal("");

    setModalOpen(false);
  };

  const onModalClose2 = () => {
    setLibur("");
    setTanggal("");
    setModalOpen2(false);
  };

  const handleDelete = async () => {
    axios({
      method: "delete",
      url: "https://smartservice.rotendaokab.go.id/api/admin/libur/" + idDelete,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${""}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          setModalOpen2(false);
          setLibur("");
          setIdDelete("");
          ambilLibur();
          //setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">Daftar Liburan</CardTitle>

                <Col md={3}>
                  <FormGroup>
                    <label>Tahun</label>
                    <Input
                      type="select"
                      value={tahun}
                      name="nama"
                      onChange={(event) => setTahun(event.target.value)}
                    >
                      {listTahun.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                {statusOtorisasi ? (
                  <Button color="danger" onClick={() => setModalOpen(true)}>
                    Tambah Libur
                  </Button>
                ) : (
                  <></>
                )}
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Tanggal</th>
                      <th>Libur</th>
                      <th>Edit</th>
                      <th className="text-center">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listLibur &&
                      listLibur.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{tanggalLengkap(item.tanggal)}</td>
                          <td>
                            {" "}
                            <Badge color={"danger"} size="md">
                              {" "}
                              {capitalizeWords(item.libur)}
                            </Badge>
                          </td>

                          <td>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Button size="sm" color="success">
                                Edit
                              </Button>
                            </div>
                          </td>
                          <td>
                            {statusOtorisasi ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Button
                                  size="sm"
                                  color="danger"
                                  onClick={() => {
                                    setLibur(item.libur);
                                    setIdDelete(item.id);
                                    setModalOpen2(true);
                                  }}
                                >
                                  delete
                                </Button>
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          size="md"
          isOpen={modalOpen}
          toggle={() => setModalOpen(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => setModalOpen(false)}>
            <div>
              <div className="headline-2">{"Tambah User"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <FormGroup row>
                <Label md={3} for="normal-field" className="text-md-right">
                  Libur
                </Label>
                <Col md={9}>
                  <Input
                    type="text"
                    id="default-textarea"
                    placeholder="Libur"
                    value={libur}
                    style={{ color: "black" }}
                    onChange={(e) => setLibur(e.target.value)}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={3} for="normal-field" className="text-md-right">
                  Tanggal
                </Label>
                <Col md={9}>
                  <Input
                    type="date"
                    id="default-textarea"
                    placeholder="username"
                    style={{ color: "black" }}
                    value={tanggal}
                    onChange={(e) => setTanggal(e.target.value)}
                    required
                  />
                </Col>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              {!saveLoading ? (
                <Button
                  className="btn-rounded"
                  color="primary"
                  size="sm"
                  onClick={() => handleSimpan()}
                >
                  {" "}
                  {"Simpan"}
                </Button>
              ) : (
                <Button className="btn-rounded" size="sm">
                  {"Simpan ..."}
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
        <Modal
          size="md"
          isOpen={modalOpen2}
          toggle={() => onModalClose2()}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => onModalClose2()}>
            <div>
              <div className="headline-2">Konfirmasi hapus Libur</div>
            </div>
          </ModalHeader>
          <ModalBody>
            Apakah anda ingin menghapus akun atas nama {libur} ?
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => onModalClose2()}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              <Button
                className="btn-rounded"
                color="primary"
                size="sm"
                onClick={handleDelete}
              >
                Hapus
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Libur;
