import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Progress,
  InputGroup,
  InputGroupAddon,
  Spinner,
  Badge,
} from "reactstrap";

import axios from "axios";
import { useState, useEffect } from "react";
import { API_TIMEOUT } from "utils";

import { capitalizeWords } from "utils/function";

import FIREBASE from "config/FIREBASE";
import swal from "sweetalert";
//import { logApp } from "utils/func";
import { useHistory } from "react-router-dom";
//import { refreshToken } from "utils/func";
import { GET_INSTANSI } from "utils";

function RegisterASN() {
  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [dokumen, setDokumen] = useState("");

  const [status, setStatus] = useState("");
  const [username, setUsername] = useState("");
  const [nama, setNama] = useState("");
  const [statusDHO, setStatusDHO] = useState("");

  const [url, setUrl] = useState("");
  const [uid, setUid] = useState("");
  const [cari, setCari] = useState(false);
  const [gambar, setGambar] = useState("");
  const [tambah, setTambah] = useState(true);
  const [searchItem, setSearchItem] = useState("");
  const [id_unit, setId_unit] = useState("");
  const [listPd, setListPd] = useState([]);

  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        //  logApp(user.uid, "User Manek");
        setCurrentUser(user);
      } else {
        history.push("/login");
      }
    }
    // refreshToken(history, setAccessToken);
  }, []);

  const onChangeDok = (e) => {
    setDokumen(e.target.files[0]);
  };

  useEffect(() => {
    if (currentUser) {
      ambilUsers();
      ambildatauser();
      ambilPerangkatDaerah();
    }
  }, [currentUser]);

  const handleSimpan = () => {
    if (!nama || !username || !status || !id_unit || !statusDHO) {
      swal("Gagal", "Data yang anda masukan harus lengkap", "error");
      return;
    }
    setSaveLoading(true);
    simpanAdmin();
  };

  function generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  function ambildatauser() {
    FIREBASE.database()
      .ref("user")
      .once("value")
      .then(function (snapshot) {
        var usersData = snapshot.val(); // usersData berisi seluruh data pengguna

        const usersArray = usersData
          ? Object.keys(usersData).map((key) => ({
              userId: key,
              ...usersData[key],
            }))
          : [];

        setTabelPerangkatDaerah(usersArray);
      })
      .catch(function (error) {
        console.log("Gagal mengambil seluruh data pengguna:", error);
      });
  }

  const simpanAdmin = async () => {
    const data = {};
    data.nama = nama;
    data.unitKerja = id_unit;
    data.password = "123456";
    data.user = username;
    data.status = status;
    data.statusDHO = statusDHO;

    var userId = generateRandomSixDigitNumber(); // Contoh ID user yang ditentukan
    data.uid = userId;

    FIREBASE.database()
      .ref("user/" + userId)
      .set(data)
      .then(function () {
        swal("Success", "User " + nama + " berhasil ditambahkan", "success");
        onCloseModal();
        setSaveLoading(false);
        ambildatauser();
      })
      .catch(function (error) {
        swal("Error", "Gagal " + error, "error");
      });
  };

  const ambilPerangkatDaerah = (id) => {
    axios({
      method: "get",
      url: GET_INSTANSI,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${""}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          if (data.length > 0) {
            const dataInstansi = data.map((item) => {
              return item.unitKerja;
            });
            setListPd(dataInstansi);
          } else {
            setListPd([]);
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilUsers = (id) => {
    axios({
      method: "get",
      url: "https://smartdh.rotendaokab.go.id/api/ambil/admin",
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${""}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          //setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const getUserEmail = (email) => {
    FIREBASE.database()
      .ref("users")
      .orderByChild("email")
      .equalTo(email)
      .once("value", (querySnapshot) => {
        //hasil disini

        let data = querySnapshot.val() ? querySnapshot.val() : [];

        let arrayOfNames = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const nama = data[key];
            arrayOfNames.push(nama);
          }
        }

        if (arrayOfNames.length > 0) {
          setCari(false);
          setNama(arrayOfNames[0].nama);
          setUsername(arrayOfNames[0].email);

          //  setDinas(arrayOfNames[0].unitKerja);
          setUrl(arrayOfNames[0].avatar);
          setUid(arrayOfNames[0].uid);
        } else {
          setCari(false);
          setNama("");
          setUsername("");
          //  setDinas(arrayOfNames[0].unitKerja);
          setUrl("");
          setUid("");

          swal("Gagal", "Username tidak ditemukan", "error");
        }
      })
      .catch((error) => {
        setCari(false);
        swal("Gagal", "Username tidak ditemukan", "failed");
      });
  };

  const cariUser = (email) => {
    setCari(true);
    getUserEmail(email);
  };

  function deleteUser() {
    // Pastikan Anda telah menginisialisasi Firebase di aplikasi Anda
    FIREBASE.database()
      .ref("user/" + uid)
      .remove()
      .then(() => {
        swal("Sukses", "Data user " + nama + " berhasil dihapus", "success");
        ambildatauser();

        onModalClose2();
      })
      .catch((error) => {
        swal(
          "Error",
          "Data user " + nama + " gagal dihapus karena " + error,
          "error"
        );
      });
  }

  const onCloseModal = () => {
    setNama("");
    setUsername("");
    setUid("");
    setUrl("");
    setGambar("");
    setSearchItem("");
    setId_unit("");
    setModalOpen(false);
  };

  const onModalClose2 = () => {
    setNama("");
    setUid("");
    setModalOpen2(false);
  };

  const handleDelete = async () => {
    deleteUser();
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">Pengguna SatuData</CardTitle>
                <Button color="danger" onClick={() => setModalOpen(true)}>
                  Tambah User
                </Button>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Nama</th>
                      <th>Perangkat Daerah</th>
                      <th>Username</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Status DHO</th>
                      <th className="text-center">Password</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelPerangkatDaerah &&
                      tabelPerangkatDaerah.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.nama}</td>
                          <td>{capitalizeWords(item.unitKerja)}</td>
                          <td style={{ cursor: "pointer" }}>{item.user}</td>

                          <td className="text-center" style={{ width: 100 }}>
                            <Badge
                              color={
                                item.status === "super admin"
                                  ? "danger"
                                  : item.status === "admin"
                                  ? "primary"
                                  : "success"
                              }
                              size="md"
                            >
                              {item.status}
                            </Badge>
                          </td>
                          <td className="text-center" style={{ width: 100 }}>
                            <Badge
                              color={
                                item.statusDHO
                                  ? item.statusDHO == "shift"
                                    ? "danger"
                                    : "primary"
                                  : "primary"
                              }
                              size="md"
                            >
                              {item.statusDHO ? item.statusDHO : "reguler"}
                            </Badge>
                          </td>
                          <td style={{ cursor: "pointer" }}>{item.password}</td>
                          <td>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() => {
                                  setNama(item.nama);
                                  setUid(item.uid);
                                  setModalOpen2(true);
                                }}
                              >
                                delete
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          size="md"
          isOpen={modalOpen}
          toggle={() => setModalOpen(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => setModalOpen(false)}>
            <div>
              <div className="headline-2">{"Tambah User"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <FormGroup row>
                <Label md={3} for="normal-field" className="text-md-right">
                  Nama
                </Label>
                <Col md={9}>
                  <Input
                    type="text"
                    id="default-textarea"
                    placeholder="nama"
                    value={nama}
                    style={{ color: "black" }}
                    onChange={(e) => setNama(e.target.value)}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={3} for="normal-field" className="text-md-right">
                  Username
                </Label>
                <Col md={9}>
                  <Input
                    type="text"
                    id="default-textarea"
                    placeholder="username"
                    style={{ color: "black" }}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={3} for="normal-field" className="text-md-right">
                  Peran
                </Label>
                <Col md={9}>
                  <Input
                    type="select"
                    id="normal-field"
                    style={{ color: "black" }}
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={""}>- pilih - </option>
                    <option value={"admin"}>admin</option>
                    <option value={"user"}>user </option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={3} for="normal-field" className="text-md-right">
                  Jenis Aktifitas - Status DHO
                </Label>
                <Col md={9}>
                  <Input
                    type="select"
                    id="normal-field"
                    style={{ color: "black" }}
                    value={statusDHO}
                    onChange={(e) => setStatusDHO(e.target.value)}
                  >
                    <option value={""}>- pilih - </option>
                    <option value={"reguler"}>Reguler</option>
                    <option value={"shift"}>Shift </option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={3} for="normal-field" className="text-md-right">
                  Perangkat Daerah
                </Label>
                <Col md={9}>
                  <Input
                    type="select"
                    id="normal-field"
                    value={id_unit}
                    style={{ color: "black" }}
                    onChange={(e) => setId_unit(e.target.value)}
                  >
                    <option value={""}>-pilih-</option>
                    {listPd.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </Input>
                </Col>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              {!saveLoading ? (
                <Button
                  className="btn-rounded"
                  color="primary"
                  size="sm"
                  onClick={() => handleSimpan()}
                >
                  {" "}
                  {"Simpan"}
                </Button>
              ) : (
                <Button className="btn-rounded" size="sm">
                  {"Simpan ..."}
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
        <Modal
          size="md"
          isOpen={modalOpen2}
          toggle={() => onModalClose2()}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => onModalClose2()}>
            <div>
              <div className="headline-2">Konfirmasi hapus akun</div>
            </div>
          </ModalHeader>
          <ModalBody>
            Apakah anda ingin menghapus akun atas nama {nama} ?
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => onModalClose2()}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              <Button
                className="btn-rounded"
                color="primary"
                size="sm"
                onClick={handleDelete}
              >
                Hapus
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default RegisterASN;
