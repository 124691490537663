import { dispatchError, dispatchLoading, dispatchSuccess } from '../utils';
import { GET_SERVICE, API_TIMEOUT } from '../utils/constant';

import axios from 'axios';

export const GET_LIST_SERVICE = 'GET_LIST_SERVICE';

export const getService = () => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_SERVICE);

    axios({
      method: 'get',
      url: GET_SERVICE,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_LIST_SERVICE, 'error');
        } else {
          //BERHASIL

          dispatchSuccess(
            dispatch,
            GET_LIST_SERVICE,
            response.data.data ? response.data.data : []
          );
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_LIST_SERVICE, error);
        alert(error);
      });
  };
};
