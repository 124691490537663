import { hitungSelisihWaktu } from "utils/function";

export const API_SMART = "https://smartservice.rotendaokab.go.id/api/admin/";
//'http://localhost:4000/api/admin/';

//'https://smartservice.rotendaokab.go.id/api/admin/';
//export const API_SMART = 'http://192.168.43.39:4000/api/';

export const API_KEY = "49d84d5ea3e55256171e1c98251c1b6b";

export const API_DAFTARHADIR = API_SMART + "daftarHadir";

export const API_DAFTARHADIR_CEK = API_SMART + "daftarHadir/cek";

export const POST_LAPORAN = API_SMART + "laporan";
export const POST_LAPORAN_KOMENTAR = API_SMART + "laporan/komentar";
export const POST_LAPORAN_LIKE = API_SMART + "laporan/like";

export const DELETE_LAPORAN_KOMENTAR = API_SMART + "laporan/laporkomen/";
export const DELETE_LAPORAN_LIKE = API_SMART + "laporan/like/";

export const EDIT_LAPORAN = API_SMART + "laporan/";

export const POST_DAFTARHADIR = API_SMART + "daftarHadir";
export const UPDATE_DAFTARHADIR = API_SMART + "daftarHadir/";
export const CEK_DAFTARHADIR = API_SMART + "daftarHadir/cek";

export const GET_DAFTARHADIR = API_SMART + "daftarHadir/getdh";

export const GET_DAFTARHADIR_ATURAN = API_SMART + "daftarHadir/aturan/";
export const GET_DH_KOORDINATOPD = API_SMART + "daftarHadir/";
export const GET_DH_ATURAN = API_SMART + "daftarHadir/";

export const GET_DAFTARHADIR_LIBUR = API_SMART + "daftarHadir/libur/";
export const GET_DAFTARHADIR_KOORDINATOPD =
  API_SMART + "daftarHadir/koordinatopd/";

export const GET_BERITA = API_SMART + "berita/";

export const GET_BERITAPORTAL = API_SMART + "beritaPortal/";

export const POST_BERITA = API_SMART + "berita";
export const EDIT_BERITA = API_SMART + "berita/";
export const DELETE_BERITA = API_SMART + "berita/";
export const GET_BERITA_DETAIL = API_SMART + "berita/";
export const UPDATE_BERITA = API_SMART + "berita/";

export const GET_NOTIFIKASI = API_SMART + "notifikasi/";
export const POST_NOTIFIKASI = API_SMART + "notifikasi";
export const EDIT_NOTIFIKASI = API_SMART + "notifikasi/";
export const DELETE_NOTIFIKASI = API_SMART + "notifikasi/";
export const GET_NOTIFIKASI_DETAIL = API_SMART + "notifikasi/";
export const UPDATE_NOTIFIKASI = API_SMART + "notifikasi/";

export const POST_NOTIFIKASI_PRIBADI = API_SMART + "notifikasipribadi";

export const GET_TOKO = API_SMART + "toko/";
export const POST_TOKO = API_SMART + "toko";
export const EDIT_TOKO = API_SMART + "toko/";
export const DELETE_TOKO = API_SMART + "toko/";
export const GET_TOKO_DETAIL = API_SMART + "toko/";
export const UPDATE_TOKO = API_SMART + "toko/";

export const GET_BANNER = API_SMART + "banner/";
export const POST_BANNER = API_SMART + "banner";
export const EDIT_BANNER = API_SMART + "banner/";
export const DELETE_BANNER = API_SMART + "banner/";
export const GET_BANNER_DETAIL = API_SMART + "banner/";

export const GET_EMERGENCY = API_SMART + "emergency/";
export const POST_EMERGENCY = API_SMART + "emergency";
export const EDIT_EMERGENCY = API_SMART + "emergency/";
export const DELETE_EMERGENCY = API_SMART + "emergency/";
export const GET_EMERGENCY_DETAIL = API_SMART + "emergency/";

export const GET_SERVICE = API_SMART + "service/";
export const POST_SERVICE = API_SMART + "service";
export const EDIT_SERVICE = API_SMART + "service/";
export const DELETE_SERVICE = API_SMART + "service/";
export const GET_SERVICE_DETAIL = API_SMART + "service/";
export const UPDATE_SERVICE = API_SMART + "service/";

export const POST_DESTINASI = API_SMART + "destinasi";
export const GET_DESTINASI = API_SMART + "destinasi";
export const EDIT_DESTINASI = API_SMART + "destinasi/";
export const GET_DESTINASI_DETAIL = API_SMART + "destinasi/";
export const DELETE_DESTINASI = API_SMART + "destinasi/";

export const UPDATE_ANGKUTSAMPAH = API_SMART + "angkutsampah/";
export const GET_ANGKUTSAMPAH_ALL = API_SMART + "angkutsampah/all/";
export const GET_ANGKUTSAMPAH_SELESAI = API_SMART + "angkutsampah/selesai/";
export const GET_ANGKUTSAMPAH_DETAIL = API_SMART + "angkutsampah/";
export const DELETE_ANGKUTSAMPAH = API_SMART + "angkutsampah/";

export const GET_PROGRES_ANGKUTSAMPAH = API_SMART + "progresangkutsampah/";

export const POST_PROGRES_ANGKUTSAMPAH = API_SMART + "progresangkutsampah/";

export const GET_DESA = API_SMART + "desa/";

export const GET_INSTANSI = API_SMART + "instansi/";

export const GET_LAPORAN = API_SMART + "laporan/";

export const DELETE_LAPORAN = API_SMART + "laporan/";

export const GET_LAPORAN_DETAIL = API_SMART + "laporan/";

export const GET_LAPORAN_KOMEN = API_SMART + "laporan/komentar";

export const GET_LAPORAN_LIKE = API_SMART + "laporan/like";

export const GET_PRODUK = API_SMART + "produk/";

export const GET_PRODUK_DEKRANASDA = API_SMART + "produk/dekranasda";

export const POST_PRODUK = API_SMART + "produk";
export const GET_PRODUK_DETAIL = API_SMART + "produk/";
export const EDIT_PRODUK = API_SMART + "produk/";
export const DELETE_PRODUK = API_SMART + "produk/";

export const GET_PESANAN_ALL = API_SMART + "pesananprosesall/";
export const GET_PESANAN_DETAIL = API_SMART + "pesanan/";
export const EDIT_PESANAN = API_SMART + "pesanan/";

export const GET_KECAMATAN = API_SMART + "kecamatan/";

export const GET_VIDEO = API_SMART + "video/";

export const GET_VIDEO_PORTAL = API_SMART + "video/portal";

export const POST_VIDEO = API_SMART + "video";

export const EDIT_VIDEO = API_SMART + "video/";
export const GET_VIDEO_DETAIL = API_SMART + "video/";
export const DELETE_VIDEO = API_SMART + "video/";

//pasar ternak
export const GET_ALL_PASARTERNAK = API_SMART + "pasarternakall/";
export const EDIT_PASARTERNAK = API_SMART + "pasarternak/";
export const GET_PASARTERNAK_DETAIL = API_SMART + "pasarternak/";
export const DELETE_PASARTERNAK = API_SMART + "pasarternak/";

//pasar ternak

///adminnnnnn
export const GET_ALL_ADMINS = API_SMART + "admins/";

export const ADMINS_LOGIN = API_SMART + "admin/login";
export const ADMINS_LOGOUT = API_SMART + "admin/";

export const POST_ADMIN = API_SMART + "admins";

export const EDIT_ADMIN_BY_ID = API_SMART + "admins/";
export const GET_ADMIN_BY_ID = API_SMART + "admins/";
export const DELETE_ADMIN = API_SMART + "admins/";
export const GET_TOKEN = API_SMART + "admins/token";

//admin

export const POST_FASILITAS_WISATA = API_SMART + "fasilitaswisata";

export const GET_FASILITASWISATA = API_SMART + "fasilitaswisata";

export const GET_FASILITASWISATA_DESTINASI =
  API_SMART + "fasilitaswisata/destinasi/";

export const GET_FASILITASWISATA_DESTINASI_DETAIL =
  API_SMART + "fasilitaswisata/";

export const EDIT_FASILITASWISATA = API_SMART + "fasilitaswisata/";

export const GET_LIBUR = API_SMART + "libur/";

export const DELETE_FASILITASWISATA = API_SMART + "fasilitaswisata/";

export const API_TIMEOUT = 120000;

export const WAKTU_KERJA5 = {
  1: "9:00",
  2: "9:00",
  3: "9:00",
  4: "9:00",
  5: "9:30",
};

export const WAKTU_KERJAShift = {
  pagi: "6:00",
  siang: "6:00",
  malam: "12:00",
};

export const WAKTU_KERJA6 = {
  1: 7,
  2: 7,
  3: 7,
  4: 7,
  5: 4,
  6: 7,
};

export const ORIGIN_CITY = "383";

export function getDatesWithoutWeekends(startDateStr, endDateStr, libur) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const dates = [];

  while (startDate <= endDate) {
    if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
      // 0 adalah Minggu dan 6 adalah Sabtu
      dates.push(new Date(startDate)); // Menambahkan tanggal ke list dengan membuat salinan object Date-nya
    }

    startDate.setDate(startDate.getDate() + 1); // Memajukan tanggal ke hari berikutnya
  }

  // Konversi array date menjadi array of objects dengan properti 'tanggal'
  const formattedDates = dates.map((date) => ({
    tanggal: date.toISOString().slice(0, 10),
  }));

  // Membuang tanggal yang sesuai dengan tanggal libur
  const datesWithoutLibur = formattedDates.filter((item) => {
    let liburKu = false;

    libur.map((item2) => {
      if (item.tanggal == item2.tanggal) {
        liburKu = true;
      }
    });
    return liburKu == false;
  });

  const datesKu = datesWithoutLibur.map((item) => {
    const dateObj = new Date(item.tanggal);
    const dayOfWeek = dateObj.getUTCDay();

    // Mendapatkan waktu kerja dari hari yang sesuai dalam format "jam:menit"
    const waktuKerja = WAKTU_KERJA5[dayOfWeek] || "0:00";

    return { ...item, waktuKerja: waktuKerja };
  });

  return datesKu;
}

export function getDatesWithoutWeekendsShift(startDateStr, endDateStr, libur) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const dates = [];

  while (startDate <= endDate) {
    dates.push(new Date(startDate)); // Menambahkan tanggal ke list dengan membuat salinan object Date-nya

    startDate.setDate(startDate.getDate() + 1); // Memajukan tanggal ke hari berikutnya
  }

  // Konversi array date menjadi array of objects dengan properti 'tanggal'
  const formattedDates = dates.map((date) => ({
    tanggal: date.toISOString().slice(0, 10),
  }));

  // Membuang tanggal yang sesuai dengan tanggal libur
  const datesWithoutLibur = formattedDates.filter((item) => {
    let liburKu = false;

    libur.map((item2) => {
      if (item.tanggal == item2.tanggal) {
        liburKu = true;
      }
    });
    return liburKu == false;
  });

  const datesKu = datesWithoutLibur.map((item) => {
    const dateObj = new Date(item.tanggal);
    const dayOfWeek = dateObj.getUTCDay();

    // Mendapatkan waktu kerja dari hari yang sesuai dalam format "jam:menit"
    const waktuKerja = "6:00";

    return { ...item, waktuKerja: waktuKerja };
  });

  return datesKu;
}

export function convertDecimalHoursToTime(decimalHours) {
  const hours = Math.floor(decimalHours);
  const minutes = Math.round((decimalHours - hours) * 60);

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
}
export function hitungtotaljamkerja(resultArray) {
  // Menghitung total waktu kerja dalam menit
  const totalWaktuKerjaMinutes = resultArray.reduce(
    (acc, item) => acc + convertToMinutes(item.waktuKerja),
    0
  );

  // Mengkonversi totalWaktuKerjaMinutes kembali ke format jam:menit
  const totalWaktuKerja = convertFromMinutes(totalWaktuKerjaMinutes);
  return totalWaktuKerja;
}

export function convertToMinutes(timeStr) {
  const [hours, minutes] = timeStr.split(":").map(Number);
  return hours * 60 + minutes;
}

export function convertFromMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}:${String(remainingMinutes.toFixed(0)).padStart(2, "0")}`;
}

export function leftJoin(A, B, keyA, keyB) {
  const resultArray = A.map((itemA) => {
    let match = B.find((itemB) => itemB[keyB] === itemA[keyA]);

    // Mengambil hari dari tanggal
    const dateObj = new Date(itemA[keyA]);
    const dayOfWeek = dateObj.getUTCDay();

    // Mendapatkan waktu kerja dari hari yang sesuai dalam format "jam:menit"
    const waktuKerja = WAKTU_KERJA5[dayOfWeek] || "0:00";

    let workingHoursInMinutes = convertToMinutes("0:00");

    if (match && match.status == "Dinas Luar") {
      workingHoursInMinutes = convertToMinutes(waktuKerja);
      match.workingHours = waktuKerja;
    } else if (match && match.waktuMasuk && match.waktuPulang) {
      const wh = hitungSelisihWaktu(match.waktuMasuk, match.waktuPulang);
      match.workingHours = wh;
      workingHoursInMinutes = convertToMinutes(wh);
    } else if (match && !match.workingHours) {
      workingHoursInMinutes = convertToMinutes("0:00");
      match.workingHours = "0:00";
    } else if (!match) {
      match = {};
      match.workingHours = "0:00";
    }

    // Menghitung persentase

    const waktuKerjaInMinutes = convertToMinutes(waktuKerja);
    const persentase = workingHoursInMinutes / waktuKerjaInMinutes;

    return {
      ...itemA,
      ...(match || {}),
      waktuKerja,
      persentase, // Menambahkan properti persentase
    };
  });

  // Menghitung total waktu kerja dalam menit
  const totalWaktuKerjaMinutes = resultArray.reduce(
    (acc, item) => acc + convertToMinutes(item.waktuKerja),
    0
  );

  // Mengkonversi totalWaktuKerjaMinutes kembali ke format jam:menit
  const totalWaktuKerja = convertFromMinutes(totalWaktuKerjaMinutes);

  // Menghitung total workingHours dalam menit
  const totalWorkingMinutes = resultArray.reduce((acc, item) => {
    const [hours, minutes] = item.workingHours.split(":").map(Number);
    return acc + hours * 60 + minutes;
  }, 0);

  // Mengkonversi totalWorkingMinutes kembali ke format jam:menit
  const totalWorkingHours = convertFromMinutes(totalWorkingMinutes);

  return {
    total_waktukerja: totalWaktuKerja,
    total_harikerja: resultArray.length,
    total_workingHours: totalWorkingHours,
    data: resultArray,
  };
}

export function leftJoinShift(A, B, keyA, keyB) {
  const resultArray = A.map((itemA) => {
    let match = B.find((itemB) => itemB[keyB] === itemA[keyA]);

    // Mengambil hari dari tanggal
    const dateObj = new Date(itemA[keyA]);
    const dayOfWeek = dateObj.getUTCDay();

    // Mendapatkan waktu kerja dari hari yang sesuai dalam format "jam:menit"
    const waktuKerja = WAKTU_KERJAShift[match && match.status] || "6:00";

    let workingHoursInMinutes = convertToMinutes("0:00");

    if (match && match.waktuMasuk && match.waktuPulang) {
      match.workingHours = match.workingHoursKu;
      workingHoursInMinutes = convertToMinutes(match.workingHoursKu);
    } else if (match && !match.workingHours) {
      workingHoursInMinutes = convertToMinutes("0:00");
      match.workingHours = "0:00";
    } else if (!match) {
      match = {};

      match.workingHours = "0:00";
    }

    // Menghitung persentase

    const waktuKerjaInMinutes = convertToMinutes(waktuKerja);
    const persentase = workingHoursInMinutes / waktuKerjaInMinutes;

    return {
      ...itemA,
      ...(match || {}),
      waktuKerja,
      persentase, // Menambahkan properti persentase
    };
  });

  // Menghitung total waktu kerja dalam menit
  const totalWaktuKerjaMinutes = resultArray.reduce(
    (acc, item) => acc + convertToMinutes(item.waktuKerja),
    0
  );

  // Mengkonversi totalWaktuKerjaMinutes kembali ke format jam:menit
  const totalWaktuKerja = convertFromMinutes(totalWaktuKerjaMinutes);

  // Menghitung total workingHours dalam menit
  const totalWorkingMinutes = resultArray.reduce((acc, item) => {
    const [hours, minutes] = item.workingHours.split(":").map(Number);
    return acc + hours * 60 + minutes;
  }, 0);

  // Mengkonversi totalWorkingMinutes kembali ke format jam:menit
  const totalWorkingHours = convertFromMinutes(totalWorkingMinutes);

  return {
    total_waktukerja: totalWaktuKerja,
    total_harikerja: resultArray.length,
    total_workingHours: totalWorkingHours,
    data: resultArray,
  };
}
