const admin = require("firebase-admin");

// Ganti 'path/to/your/service-account-file.json' dengan lokasi aktual file JSON Anda
var serviceAccount = require("./firebase_adminsdk.json");

admin.initializeApp({
  credential: admin.credential.cert(serviceAccount),
  // Jika Anda menggunakan Firebase Database, tambahkan juga URL database Anda seperti berikut
  // databaseURL: "https://your-database-url.firebaseio.com"
});

const FIREBASE_ADMIN = admin;

export default FIREBASE_ADMIN;
