import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
  FormGroup,
  Input,
  CardFooter,
  Progress,
  Badge,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import { API_TIMEOUT } from "utils";

import axios from "axios";

import ReactHTMLTableToExcel from "react-html-table-to-excel";

import {
  getDatesWithoutWeekends,
  hitungtotaljamkerja,
  convertDecimalHoursToTime,
} from "utils";
import { GET_LIBUR } from "utils";
import { convertToMinutes } from "utils";
import { capitalizeWords } from "utils/function";

export default function LaporRangePD() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getListDHLoading, getListDHResult, getListDHError } = useSelector(
    (state) => state.DaftarHadirReducer
  );

  // auth

  const [labelBulan, setLabelBulan] = useState("");
  const [bulan, setBulan] = useState("");

  const [instansi, setInstansi] = useState("");
  const [listLibur, setListLibur] = useState([]);
  const [totalHK, setTotalHK] = useState(0);
  const [totalWH, setTotalWH] = useState("0:00");
  const [rerataWH, setRerataWH] = useState(0);

  const [kehadiran, setKehadiran] = useState(0);
  const [jumlahAsn, setJumlahAsn] = useState(0);

  const [listLaporPD, setListLaporPD] = useState();
  const [hariEfektif, setHariEfektif] = useState("");

  const ambilTanggal = (date) => {
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = "0" + month;
    }
    var day = date.getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }
    var year = date.getFullYear().toString();
    var tanggalKu = year + "-" + month + "-" + day;
    return tanggalKu;
  };

  const ambilTanggalAwal = (date) => {
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = "0" + month;
    }
    var day = date.getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }
    var year = date.getFullYear().toString();
    var tanggalKu = year + "-" + month + "-" + "01";
    return tanggalKu;
  };

  const [tanggalAwal, setTanggalAwal] = useState(() =>
    ambilTanggalAwal(new Date())
  );

  const [tanggalAkhir, setTanggalAkhir] = useState(() =>
    ambilTanggal(new Date())
  );

  const [dateTimeKu, setDateTimeKu] = useState("");
  const labelBulanKu = {
    0: "Januari",
    1: "Februari",
    2: "Maret",
    3: "April",
    4: "Mei",
    5: "Juni",
    6: "Juli",
    7: "Agustus",
    8: "September",
    9: "Oktober",
    10: "November",
    11: "Desember",
  };

  const getTime = async () => {
    const response = await axios.get("https://worldtimeapi.org/api/ip");
    const data = response.data;
    setDateTimeKu(data.datetime);
  };

  useEffect(() => {
    if (tanggalAwal && tanggalAkhir) {
      loadingData();
    }
  }, []);

  useEffect(() => {
    if (getListDHResult && totalWH) {
      let rr = 0;
      getListDHResult.map((item) => {
        rr =
          rr + convertToMinutes(convertDecimalHoursToTime(item.workingHours));
      });
      const avr = rr / getListDHResult.length;
      setRerataWH(avr);
    }
  }, [getListDHResult, totalWH]);

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    setInstansi(user.unitKerja);
  }, []);

  //auth

  //dari smart service

  const getLaporanPD = (data) => {
    axios({
      method: "post",
      url: "https://smartservice.rotendaokab.go.id/api/admin/unitkerja/dho/range",
      timeout: API_TIMEOUT,
      data: data,

      headers: {
        Authorization: `Bearer ${""}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          alert("errorr");
        } else {
          //BERHASIL

          const result = response.data.data;
          const hariEfektif = response.data.hariEfektif;
          setHariEfektif(hariEfektif);
          const filter = result.filter((item) => {
            return item.posisi == 0;
          });

          let rerata = 0;
          let jumlahASN = 0;
          filter.map((item) => {
            jumlahASN = jumlahASN + item.jumlah_asn;
            rerata =
              rerata +
              (item.Hadir +
                item.Dinas_Luar +
                item.Tugas_Belajar +
                item.Izin_Belajar);
          });

          setJumlahAsn(jumlahASN);
          setKehadiran(rerata.toFixed(0));
          setListLaporPD(filter);
        }
      })
      .catch((error) => {
        // ERROR

        alert("error");
      });
  };

  //dari smart service

  useEffect(() => {
    getTime();
  }, []);

  const loadingData = () => {
    setListLaporPD();
    const data2 = {
      tanggalawal: tanggalAwal,
      tanggalakhir: tanggalAkhir,
    };
    setLabelBulan(labelBulanKu[parseInt(bulan) - 1]);
    getLaporanPD(data2);
  };

  useEffect(() => {
    if (listLibur) {
      const d = getDatesWithoutWeekends(tanggalAwal, tanggalAkhir, listLibur);
      const wh = hitungtotaljamkerja(d);
      setTotalHK(d.length);
      setTotalWH(wh);
    }
  }, [listLibur]);

  return (
    <div className="content">
      <Row>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-globe text-warning" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Hari Kerja</p>
                    <CardTitle tag="p">
                      {hariEfektif && hariEfektif.hariEfektif5.length}
                    </CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="fas fa-sync-alt" /> hari
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-money-coins text-success" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Jumlah ASN</p>
                    <CardTitle tag="p">{jumlahAsn} </CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-calendar" /> {"orang"}
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-vector text-danger" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Rerata Kehadiran</p>
                    <CardTitle tag="p">{kehadiran}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-clock" /> orang
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="12" xs="5">
                  <div className={`d-flex justify-content-between`}>
                    <div className="d-flex flex-column mt-3">
                      <div>
                        <p className="body-2">
                          {((kehadiran / jumlahAsn) * 100).toFixed(0)}%
                          Kehadiran{" "}
                        </p>
                      </div>
                      <Progress
                        color={
                          (kehadiran / jumlahAsn) * 100 > 50
                            ? "success"
                            : "danger"
                        }
                        className="progress-xs"
                        value={(kehadiran / jumlahAsn) * 100}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-clock" /> Kinerja
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag="h4">
                    {"Perangkat Daerah kab. Rote Ndao"}
                  </CardTitle>
                </Col>
              </Row>
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <Col>
                  <FormGroup>
                    <label>Tanggal awal</label>
                    <Input
                      type="date"
                      value={tanggalAwal}
                      onChange={(e) => {
                        const date = new Date(e.target.value);
                        const awal = ambilTanggal(date);

                        setTanggalAwal(awal);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Tanggal akhir</label>
                    <Input
                      type="date"
                      value={tanggalAkhir}
                      onChange={(e) => {
                        const date = new Date(e.target.value);
                        const akhir = ambilTanggal(date);

                        setTanggalAkhir(akhir);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col>
                  <Button onClick={loadingData}>Load</Button>
                </Col>
                <Col>
                  <Col md={3}>
                    <ReactHTMLTableToExcel
                      className="btn"
                      table="laporBulan"
                      filename={
                        "Rekap DHO " +
                        tanggalAwal +
                        " - " +
                        tanggalAkhir +
                        " " +
                        "Perangkat Daerah" +
                        new Date()
                      }
                      sheet="Data "
                      buttonText="Export to Exl"
                    />
                  </Col>
                </Col>
              </Row>
            </CardHeader>

            <CardBody style={{ overflowY: "auto" }}>
              <Table id="laporBulan">
                <thead className="text-primary">
                  <tr>
                    <th>No</th>
                    <th>Perangkat Darah</th>

                    <th>Hari Kerja</th>
                    <th>Jumlah ASN </th>
                    <th>
                      Hadir{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>
                    <th>
                      Dinas Luar{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>
                    <th>
                      Izin{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>
                    <th>
                      Sakit{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>
                    <th>
                      Cuti{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>
                    <th>
                      Tugas Belajar{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>

                    <th>
                      Izin Belajar{" "}
                      <Badge color="warning">
                        <span style={{ color: "white" }}>R</span>
                      </Badge>
                    </th>
                    <th>Performance</th>
                    <th>Tidak Mengisi</th>
                    <th>Aksi</th>
                  </tr>
                </thead>

                <tbody>
                  {listLaporPD ? (
                    listLaporPD.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td>{capitalizeWords(item.unitKerja)}</td>
                        <td>
                          {item.hariKerja == 0 ? (
                            <Badge color="primary">5</Badge>
                          ) : (
                            <Badge color="success">6</Badge>
                          )}{" "}
                        </td>
                        <td>{item.jumlah_asn}</td>
                        <td>{item.Hadir.toFixed(0)} </td>
                        <td>{item.Dinas_Luar.toFixed(0)} </td>
                        <td>{item.Izin.toFixed(0)} </td>
                        <td>{item.Sakit.toFixed(0)} </td>
                        <td>{item.Cuti.toFixed(0)} </td>
                        <td>{item.Tugas_Belajar.toFixed(0)} </td>
                        <td>{item.Izin_Belajar.toFixed(0)} </td>
                        <td className="text-center" style={{ width: 150 }}>
                          {" "}
                          <div className={`d-flex justify-content-between`}>
                            <div className="d-flex flex-column mt-3">
                              <div>
                                <p className="body-2">
                                  {item.jumlah_asn
                                    ? Math.round(
                                        ((item.Hadir +
                                          item.Dinas_Luar +
                                          item.Tugas_Belajar +
                                          item.Izin_Belajar) /
                                          item.jumlah_asn) *
                                          100
                                      )
                                    : 0}
                                  % kehadiran{" "}
                                </p>
                              </div>
                              <Progress
                                color={
                                  Math.round(
                                    ((item.Hadir +
                                      item.Dinas_Luar +
                                      item.Tugas_Belajar +
                                      item.Izin_Belajar) /
                                      item.jumlah_asn) *
                                      100
                                  ) > 50
                                    ? "success"
                                    : "danger"
                                }
                                className="progress-xs"
                                value={Math.round(
                                  ((item.Hadir +
                                    item.Dinas_Luar +
                                    item.Tugas_Belajar +
                                    item.Izin_Belajar) /
                                    item.jumlah_asn) *
                                    100
                                )}
                              />
                            </div>
                          </div>
                        </td>
                        <td className="text-center" style={{ width: 150 }}>
                          {" "}
                          <div className={`d-flex justify-content-between`}>
                            <div className="d-flex flex-column mt-3">
                              <div>
                                <p className="body-2">
                                  {item.jumlah_asn
                                    ? Math.round(
                                        ((item.jumlah_asn -
                                          (item.Hadir +
                                            item.Dinas_Luar +
                                            item.Tugas_Belajar +
                                            item.Izin_Belajar +
                                            item.Izin +
                                            item.Sakit +
                                            item.Cuti)) /
                                          item.jumlah_asn) *
                                          100
                                      )
                                    : 0}
                                  % Tidak Mengisi{" "}
                                </p>
                              </div>
                              <Progress
                                color={
                                  Math.round(
                                    ((item.jumlah_asn -
                                      (item.Hadir +
                                        item.Dinas_Luar +
                                        item.Tugas_Belajar +
                                        item.Izin_Belajar +
                                        item.Izin +
                                        item.Sakit +
                                        item.Cuti)) /
                                      item.jumlah_asn) *
                                      100
                                  ) < 50
                                    ? "success"
                                    : "danger"
                                }
                                className="progress-xs"
                                value={Math.round(
                                  ((item.jumlah_asn -
                                    (item.Hadir +
                                      item.Dinas_Luar +
                                      item.Tugas_Belajar +
                                      item.Izin_Belajar +
                                      item.Izin +
                                      item.Sakit +
                                      item.Cuti)) /
                                    item.jumlah_asn) *
                                    100
                                )}
                              />
                            </div>
                          </div>
                        </td>

                        <td>
                          <Link
                            className="btn btn-warning"
                            to={
                              "/admin/kinerjaPD/" +
                              tanggalAwal +
                              ",," +
                              tanggalAkhir +
                              ",," +
                              item.unitKerja
                            }
                          >
                            <i className="nc-icon nc-ruler-pencil"></i> Detail
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" align="center">
                        <Spinner color="primary" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
